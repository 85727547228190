import * as React from 'react';

import { isMobile } from 'pact.drural.utils'
import { Button, Modal, Box, Grid, Typography } from '@material-ui/core';
import { translate, useTranslations } from 'pact.drural.app-skeleton';


const style = () => {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile() ? "100%" : "90%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 8,
    overflow: 'scroll',
    height: "100%"
  }
};

const AnswersModal = ({questionnaire}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const t = useTranslations();

  return (
    <>
      <Button onClick={handleOpen} style={{width: "22rem"}} variant="contained" className="hide-on-print">{ translate("QUESTIONNAIRE_RESULTS_ANSWERS_BUTTON", t) }</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style()}>
          <Typography id="modal-modal-title" variant="h3" style={{marginBottom: "3rem"}}>
            { translate("QUESTIONNAIRE_RESULTS_ANSWERS", t) }
          </Typography>
          <Grid container spacing={4}>
            {
              questionnaire.map((section, i) => {
                return(
                    <Grid item md={6} xs={12} key={i}>
                      <Typography variant="h6" style={{marginBottom: ".5rem"}}>{section.header}</Typography>
                      {
                        section.questions.map((question, i) => {
                          let bgColor = i % 2 !== 0 ? "#f5f5f5" : "#E0DEE3"
                          return (
                            <Grid container key={i}>
                              <Grid item md={8} xs={8} style={{backgroundColor: bgColor}}>
                                <Typography style={{marginLeft: "1rem"}} variant="body2">{question.text}</Typography>
                              </Grid>
                              <Grid item md={4} xs={4} style={{backgroundColor: bgColor, textAlign: "right"}}>
                                <Typography variant="body2" style={{marginRight: ".5rem"}}><b>{
                                  question.type === 0
                                  ? question.selectedValue
                                  : question.isOtherOptionSelected
                                    ? question.otherOptionInput
                                    : question.options.find(o => o.isSelected) !== undefined ? question.options.find(o => o.isSelected).text : ""
                                }</b></Typography>
                              </Grid>
                              <Grid md={1} xs={false}  style={{backgroundColor: bgColor}} />
                            </Grid>
                          )
                        })
                      }
                    </Grid>
                  )
              })
            }
          </Grid>
          <Button style={{marginTop: "3rem", width: "22rem"}} variant="contained" color="primary" onClick={handleClose} >{ translate("BUTTON_CLOSE", t) }</Button>
        </Box>
      </Modal>
    </>
  );
}
export default AnswersModal;