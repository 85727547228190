import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setUserRole } from "./actions/clientActions";
import { UserRole } from "./enums/UserRole";
import { moveToStep } from "./actions/questionnaireActions";
import { useUserRole } from "./hooks/useUserRole";

interface UserRoleHandlerProps {
  selectRole: (role: UserRole) => void;
}

export const UserRoleHandler = (props: UserRoleHandlerProps) => {
  const { isLoading, userRole } = useUserRole();

  useEffect(() => {
    if (!isLoading && userRole) {
      props.selectRole(userRole);
    }
  });

  if (isLoading) {
    return <></>;
  }

  return <></>;
};

const mapDispatchToProps = dispatch => {
  return {
    selectRole: (role: UserRole) => {
      dispatch(setUserRole(role));
      if (role === UserRole.Professionel) {
        dispatch(moveToStep("professionalentry"));
      } else {
        dispatch(moveToStep("entry"));
      }
    }
  };
};

export default connect(null, mapDispatchToProps)(UserRoleHandler);
