import { Container, List, ListItem, ListItemText, FormControlLabel, Radio, Grid, TextField, Typography, Divider } from '@material-ui/core';
import * as React from 'react'

import { validationErrorTextColor, defaultTextColor } from '../../../utils/config';

const SelectionQuestion = ({header, options, validationFailed, onValueSelect, onOtherValueEnter, othersOptions}) => {
    let headerColor = validationFailed ? validationErrorTextColor : defaultTextColor

    return(
        <Container style={{marginBottom: "4rem"}}>
            <Divider style={{marginBottom: "4rem"}} />
            <Typography align="left" variant="h4" style={{color: headerColor, marginLeft: "15%", fontWeight: 400, marginBottom: "2rem"}}>{validationFailed ? "*" : ""} {header}</Typography>
            <Grid container spacing={2}>
                <Grid item md={2}></Grid>
                <Grid item md={8}>
                    <List>
                        <Grid container>
                            {
                                options.map((option, i) => (
                                    <Grid item md={4}>
                                        <ListItem
                                        key={i}
                                        role={undefined}
                                        button
                                        onClick={() => onValueSelect(option.id)}
                                    >
                                        <FormControlLabel
                                        control={<Radio />}
                                        checked={option.isSelected}
                                        tabIndex={-1}
                                        />
                                        <ListItemText
                                        primary={option.text}
                                        />
                                    </ListItem>
                                    </Grid>
                                    
                                ))
                            }
                        </Grid>

                    {
                        othersOptions.hasOtherOption 
                            ? <Grid container>
                                <Grid item md={4}>
                                    <ListItem
                                    role={undefined}
                                    button
                                    onClick={() => onValueSelect(9999)}
                                >
                                    <FormControlLabel
                                    control={<Radio />}
                                    checked={othersOptions.isSelected}
                                    tabIndex={-1}
                                    />
                                    <ListItemText primary={othersOptions.text}/>
                                    </ListItem>
                                </Grid>
                                <Grid item md={8}>
                                    <TextField
                                        value={othersOptions.input} 
                                        style={{marginTop: "1rem"}} 
                                        variant="standard" 
                                        fullWidth 
                                        disabled={!othersOptions.isSelected}
                                        onChange={(event) => onOtherValueEnter(event.target.value)} 
                                        />
                                </Grid>
                            </Grid>
                            : <></>
                    }
                    </List>
                </Grid>
            </Grid>
        </Container>
    )
}

export default SelectionQuestion;