import { getUrlParameter } from "pact.drural.utils";
import { ACCEPT_LEGAL_AGREEMENT, ENTER_OTHER_VALUE, MOVE_TO_STEP, NEXT_QUESTION, PREVIOUS_QUESTION, SELECT_VALUE, SET_ANSWER_ID, SET_QUESTIONNAIRE, SET_QUESTIONNAIRE_LIST } from "../actions/questionnaireActions";

const defaultState = {
    currentSectionIndex: 0,
    questionnaire: null,
    answeredQuestionnaireId: "",
    currentStep: "",
    questionnaireId: getUrlParameter("qid"),
    token: getUrlParameter("token"),
    list: {
        items: [],
        selectedItems: [],
        totalCount: 1,
        pageInfo: { },
        isLoading: false
    }
}

const mapQuestions = (section, action) => {
    let mappedQuestions = section.questions.map((question, i) => {
        if (i === action.questionIndex) {
            if (question.type !== 0) {
                if (action.value === 9999) {
                    if (question.type === 2) {
                        return {
                            ...question,
                            isOtherOptionSelected: !question.isOtherOptionSelected,
                        }
                    } else {
                        return {
                            ...question,
                            isOtherOptionSelected: !question.isOtherOptionSelected,
                            options: question.options.map(
                                (option) => { return { ...option, isSelected: false } }
                            )
                        }
                    }

                } else if (question.type === 2) {
                    return {
                        ...question,
                        options: question.options.map(
                            (option) => option.id === action.value ? { ...option, isSelected: !option.isSelected } : option
                        ),
                        isOtherOptionSelected: false
                    }
                } else {
                    return {
                        ...question,
                        options: question.options.map(
                            (option) => option.id === action.value ? { ...option, isSelected: !option.isSelected } : { ...option, isSelected: false }
                        ),
                        isOtherOptionSelected: false
                    }
                }
            } else {
                return {...question, selectedValue: action.value } 
            }
        }

        return question;
    })

    return {...section, questions: mappedQuestions};
}

const setOtherEntry = (section, action) => {
    let mappedQuestions = section.questions.map((question, i) => {
        if (i === action.questionIndex) {
            return {...question, otherOptionInput: action.value } 
        }

        return question;
    })

    return {...section, questions: mappedQuestions};
}


const questionnaireReducer = (state = defaultState, action) => {
    switch (action.type) {
        case ACCEPT_LEGAL_AGREEMENT:
            return {
                ...state,
                questionnaire: {
                    ...state.questionnaire,
                    legalAgreements: state.questionnaire.legalAgreements.map((agreement, i) => {
                        if (action.index === i) {
                            return {
                                ...agreement,
                                isChecked: !agreement.isChecked
                            }
                        } else {
                            return agreement;
                        }
                    })
                }
            }
        case SET_QUESTIONNAIRE:
            return {
                ...state,
                questionnaire: action.questionnaire,
                currentSectionIndex: 0
            }
        case SET_QUESTIONNAIRE_LIST: 
            return {
                ...state,
                list: {
                    ...state.list,
                    items: action.questionnaires
                }
            }
        case NEXT_QUESTION:
            return {
                ...state,
                currentSectionIndex: state.currentSectionIndex + 1
            }
        case PREVIOUS_QUESTION:
            return {
                ...state,
                currentSectionIndex: state.currentSectionIndex - 1
            }
        case SELECT_VALUE:
            return {
                ...state,
                questionnaire: {
                    ...state.questionnaire,
                    sections: state.questionnaire.sections.map(
                        (section, i) => i === action.sectionIndex 
                                    ? mapQuestions(section, action)
                                    : section
                        
                    )
                }
            }
        case ENTER_OTHER_VALUE:
            return {
                ...state,
                questionnaire: {
                    ...state.questionnaire,
                    sections: state.questionnaire.sections.map(
                        (section, i) => i === action.sectionIndex 
                                    ? setOtherEntry(section, action)
                                    : section
                    )
                }
            }
        case MOVE_TO_STEP:
            return {
                ...state,
                currentStep: action.step
            }
        case SET_ANSWER_ID:
            return {
                ...state,
                answeredQuestionnaireId: action.id
            }
        default:
            return state;
    }
}

export default questionnaireReducer;