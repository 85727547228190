import * as React from 'react'
import { connect } from 'react-redux'

import { Typography, Container } from '@material-ui/core';
import { setNavigationOptions } from 'pact.drural.app-skeleton';

import { toggleSendModal } from '../../actions/modalActions';
import SendModal from '../elements/Questionnaire/SendModal';
import { moveToStep } from '../../actions/questionnaireActions';
import { ServiceGridList } from 'pact.drural.ui';

const NavigationOptions = (props) => {
    return {
        isBackEnabled: true,
        isContinueEnabled: true, 
        onBackClick: () => props.moveToStep("questionnaireresult"),
        onContinueClick: () => props.moveToStep("")  
    }
} 

class Recommendations extends React.Component {
    componentDidMount() {
        this.props.setNavigation(NavigationOptions(this.props))
        this.props.loadServices()
    }

    render() {
        return(
            <Container align="center">
                <h1>Recommended for you</h1>
                <Typography variant="subtitle1" style={{fontSize: 14}}>
                    This a list of services recommended based on your results of the Positive Health questionanire
                </Typography>
                <Typography variant="body1" style={{marginTop: "4rem", marginBottom: "2rem"}}>
                    Click on a service for a detailed description and / or to book it.
                </Typography>

                <ServiceGridList />

                <Container align="right" style={{marginTop: "4rem"}}>
                    <SendModal 
                        handleOpen={this.props.toggleSendModal} 
                        handleClose={this.props.toggleSendModal} 
                        open={this.props.isSendModalOpen} 
                    />
                </Container>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isSendModalOpen: state.modal.isSendModalOpen
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNavigation: (options) => {
            dispatch(setNavigationOptions(options))
        },
        toggleSendModal: () => {
            dispatch(toggleSendModal())
        },
        moveToStep: (step) => {
            dispatch(moveToStep(step))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recommendations)