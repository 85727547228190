import * as React from 'react'
import { Button, Card, CardMedia } from '@material-ui/core'

const SelectionCard = ({header, subheader, buttonText, onButtonClick, href, enabled = true}) => {
    return (
        <Card>
            <CardMedia
                component="img"
                image="/f63a0bebef266cb6366981631915929e.png"
                style={{
                    borderRadius: '50%',
                    paddingTop: '2rem',
                    marginTop: '1rem',
                    width: '40%'
                }}
            />
            <h2>{header}</h2>
            <p>{subheader}</p>
            <Button 
                variant="contained" 
                color="primary" 
                onClick={onButtonClick}
                style={{marginBottom: '2rem'}}
                disabled={!enabled}
                href={href}
            >
                    {buttonText}
            </Button>
        </Card>
    )
}

export default SelectionCard;