import gql from "graphql-tag";

export const GetUserSellerInfoQuery = gql`
  query GetUserSellerInfo {
    me {
      id
      isSeller
      __typename
    }
  }
`;
