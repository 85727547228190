import React, { useState } from "react";

import { isMobile } from "pact.drural.utils";
import {
  Box,
  Button,
  Modal,
  Typography,
  Grid,
  IconButton
} from "@material-ui/core";
import { HelpOutlineOutlined } from "@material-ui/icons";

const style = () => {
  return {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile() ? "100%" : "90rem",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 8,
    height: "67rem"
  };
};

export const SpiderwebModal = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        variant="contained"
        color="secondary"
        style={{
          marginTop: "3.5rem",
          float: "right"
        }}
        className="hide-on-print"
      >
        <HelpOutlineOutlined
          style={{
            float: "right",
            width: "36px",
            height: "36px"
          }}
        />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style()}>
          <Typography
            id="modal-modal-title"
            variant="h3"
            style={{ marginBottom: "3rem" }}
          >
            Uitleg van deze grafiek
          </Typography>
          <Typography style={{ marginBottom: "4rem" }}>
            Het spinnenweb helpt om met positieve gezondheid aan de slag te
            gaan. Het bestaat uit zes onderwerpen. Bij elk onderwerp heb je een
            score gekregen. Deze scores vormen je ‘gezondheidsoppervlak’ in het
            spinnenweb. Dit kan helpen om te zien hoe jij vindt dat het met je
            gaat.
          </Typography>
          <Typography style={{ marginBottom: "4rem" }}>
            Door op één onderwerp je gedrag te veranderen, kan je ook op de
            andere onderwerpen verschil merken. Zo kun je het
            gezondheidsoppervlak van je spinnenweb groter maken, en je positieve
            gezondheid veranderen.
          </Typography>
          <Typography style={{ marginBottom: "3rem" }}>
            Je kunt jezelf een aantal vragen over het spinnenweb stellen.
            <ul>
              <li>
                Wat trekt de aandacht als je kijkt naar het
                gezondheidsoppervlak?
              </li>
              <li>
                Wat is voor jou belangrijk? Wat zijn jouw dromen? Wat is voor
                jou een reden om iets te doen?
              </li>
              <li>Wat wil je veranderen of meer aandacht geven?</li>
              <li>Wat kan een eerste stap zijn?</li>
              <li>Is er iets wat je daarbij tegenhoudt?</li>
              <li>Wat of wie kan je helpen?</li>
            </ul>
            Over deze vragen kun je in het gesprek met de leefstijlcoach praten.
          </Typography>
          <Grid container>
            <Grid item md={6} xs={6}>
              <Button
                style={{
                  width: "22rem",
                  float: "left",
                  marginLeft: "3rem"
                }}
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                Sluiten
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
