export const SET_QUESTIONNAIRE_VALIDATION_SUMMARY = "SET_QUESTIONNAIRE_VALIDATION_SUMMARY"
export const CLEAR_QUESTIONNAIRE_VALIDATION_SUMMARY = "CLEAR_QUESTIONNAIRE_VALIDATION_SUMMARY"
export const VALIDATE_CUSTOMER_SUMMARY = "VALIDATE_CUSTOMER_SUMMARY"
export const SET_LEGAL_VALIDATION_SUMMARY = "SET_LEGAL_VALIDATION_SUMMARY"
export const CLEAR_LEGAL_VALIDATION_SUMMARY = "CLEAR_LEGAL_VALIDATION_SUMMARY"

export const setLegalValidationSummary = (summary) => ({
    type: SET_LEGAL_VALIDATION_SUMMARY,
    summary
})

export const clearLegalValidationSummary = () => ({
    type: CLEAR_LEGAL_VALIDATION_SUMMARY
})

export const setQuestionnaireValidationSummary = (summary) => ({
    type: SET_QUESTIONNAIRE_VALIDATION_SUMMARY,
    summary
})

export const clearQuestionnaireValidationSummary = () => ({
    type: CLEAR_QUESTIONNAIRE_VALIDATION_SUMMARY
})

export const validateLegalAgreements = (legalAgreements, onSuccess) => dispatch => {
    if (!legalAgreements) {
        onSuccess();
    }

    console.log(legalAgreements)

    let summary = [];
    legalAgreements.forEach((agreement, i) => {
        if (!agreement.isChecked && i === 0) {
            summary.push(agreement)
        }
    })

    if (summary.length > 0) {
        return dispatch(setLegalValidationSummary(summary))
    } else {
        dispatch(clearLegalValidationSummary())
        onSuccess()
    }
}

export const validateQuestionnaireSection = (section, onSuccess) => dispatch => {
    let unansweredQuestions = [];
    section.questions.forEach(question => {
        if (question.isRequired) {
            if (question.type === 0 && question.selectedValue === undefined) {
                unansweredQuestions.push(question)
            }
    
            if (question.type !== 0) {
                if (question.options.every(o => !o.isSelected) && !question.isOtherOptionSelected) {
                    unansweredQuestions.push(question)
                }
            }
        }
    })

    if (unansweredQuestions.length > 0) {
        return dispatch(setQuestionnaireValidationSummary(unansweredQuestions))
    } else {
        dispatch(clearQuestionnaireValidationSummary())
        onSuccess()
    }
}

export const validateCustomer = (firstName, onSuccess) => dispatch => {
    let isValid = firstName !== undefined && firstName !== null && firstName !== "";

    dispatch(setCustomerValidationSummary(isValid))
    if (isValid) {
       onSuccess();
    }
}

const setCustomerValidationSummary = (isValid) => ({
    type: VALIDATE_CUSTOMER_SUMMARY,
    isValid : isValid
})
