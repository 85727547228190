import { combineReducers } from 'redux'
import modalReducer from './modalReducer';
import questionnaireReducer from './questionaireReducer';
import clientReducer from './clientReducer'
import validationReducer from './validationReducer';
import recommendationReducer from './recommendationReducer'
import errorReducer from './errorReducer'
import customerReducer from "./customerReducer";

import { authenticationReducer, backdropReducer, localizationReducer, navigationReducer, userReducer } from "pact.drural.app-skeleton";
import { notificationReducer } from 'pact.drural.notifications';
import { serviceReducer } from 'pact.drural.ui';


const rootReducer = combineReducers({
    client: clientReducer,
    customer: customerReducer,
    user: userReducer,
    questionnaire: questionnaireReducer,
    navigation: navigationReducer,
    validation: validationReducer,
    modal: modalReducer,
    notification: notificationReducer,
    authentication: authenticationReducer,
    backdrop: backdropReducer,
    service: serviceReducer,
    localization: localizationReducer,
    recommendations: recommendationReducer,
    error: errorReducer
});

export default rootReducer;