import { Container, Divider, Grid, Slider, Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React, {useEffect} from 'react'
import { defaultTextColor, validationErrorTextColor } from '../../../utils/config';


const StyledSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-track': {
        border: 'none',
        opacity: 0
      },
    '& .MuiSlider-mark': {
      height: 6,
      width: 1,
      '&.MuiSlider-markActive': {
        opacity: 1,
        height: 6,
        width: 1,
        backgroundColor: "currentColor"
      }
    }
  }));

const SliderQuestion = ({header, selectedValue, validationFailed, config, onValueSelect}) => {
    let headerColor = validationFailed ? validationErrorTextColor : defaultTextColor

    useEffect(() => {
      if (selectedValue === -1 || selectedValue === undefined) {
        onValueSelect(5)
      }
    })

    return(
        <Container style={{marginBottom: "4rem"}}>
            <Divider style={{marginBottom: "4rem"}} />
            <Typography variant="h4" style={{color: headerColor, fontWeight: 400, marginBottom: "2rem"}}>{validationFailed ? "*" : ""} {header}</Typography>
            <Typography variant="subtitle1" style={{marginTop: "-1.75rem"}}>Klik op het cijfer</Typography>
            <Grid container spacing={2}>
                <Grid item md={2} xs={false}></Grid>
                <Grid item md={8} xs={12}>
                    <StyledSlider
                        value={selectedValue}
                        defaultValue={0}
                        valueLabelDisplay="auto"
                        aria-label="Default" 
                        min={config.rangeMin}
                        max={config.rangeMax}
                        marks={config.marks}
                        style={{marginTop: "1rem", marginBottom: "3rem"}}
                        onChange={(props, value) => onValueSelect(value)}
                    /> 
                </Grid>
            </Grid>
        </Container>
    )
}

export default SliderQuestion;