import { Grid, Container } from '@material-ui/core'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { toggleSendModal } from '../../actions/modalActions'
import { setNavigationOptions, useTranslations, translate } from 'pact.drural.app-skeleton';
import SendModal from '../elements/Questionnaire/SendModal'
import SelectionCard from '../elements/SelectionCard'
import { moveToStep } from '../../actions/questionnaireActions';

const NavigationOptions = {
    isBackEnabled: true,
    isContinueVisible: false, 
    onBackClick: "/",
    onContinueClick: "/"   
}

const QuestionnaireChoice = (props) => {
    const t = useTranslations()

    useEffect(() => {
        props.setNavigation(NavigationOptions)
    }, [props])

    return (
        <Container align="center">
            <h1>{ translate("QUESTIONNAIRE_SELECTION_HEADER", t) }, { props.user.firstName }</h1>
            <h3>{ translate("QUESTIONNAIRE_SELECTION_SUBHEADER", t) }</h3>
            <Grid container spacing={2}>
                <Grid item md={2} xs={false}></Grid>
                <Grid item md={4} xs={12}>
                    <SelectionCard
                        header={ translate("PROFESSIONAL_FILL_NOW_HEADER", t) }
                        subheader=""
                        buttonText={ translate("BUTTON_SELECT", t) }
                        onButtonClick={() => props.moveToStep("customer-selection")}
                    />
                </Grid>
                <Grid item md={4} xs={12}>
                    <SelectionCard
                        header={ translate("PROFESSIONAL_SEND_TO_CLIENT_HEADER", t) }
                        subheader=""
                        buttonText={ translate("BUTTON_SELECT", t) }
                        onButtonClick={() => props.toggleSendModal()}
                    />
                </Grid>
            </Grid>
            <SendModal 
                handleOpen={props.toggleSendModal} 
                handleClose={props.toggleSendModal} 
                open={props.isSendModalOpen} 
                displayButton={false}
                header={ translate("BUTTON_SEND", t) }
            />
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        isSendModalOpen: state.modal.isSendModalOpen,
        user: state.user.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNavigation: (options) => {
            dispatch(setNavigationOptions(options))
        },
        toggleSendModal: () => {
            dispatch(toggleSendModal())
        },
        moveToStep: (step) => {
            dispatch(moveToStep(step))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireChoice)