import { Button, Modal, Box, Typography, Grid, TextField, Snackbar, CircularProgress, Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as React from 'react';
import { isMobile, validateEmail as validate } from 'pact.drural.utils'
import { translate, useTranslations } from 'pact.drural.app-skeleton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: isMobile() ? "95%" : "50rem",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 8
};

const SendModal = ({handleOpen, handleClose, open, header = "Send results by email", displayButton = true}) => {
  const [enteredEmail, setEmail] = React.useState("");
  const [isEmailValid, setEmailValidation] = React.useState(true);
  const validateEmail = (address) => {
    let isEmail = validate(address);
    setEmailValidation(isEmail);

    return isEmail;
  }

  const [sendingEmail, toggleEmailSendingSpinner] = React.useState(false);
  const sendEmail = (address) => {
    if (validateEmail(address)) {
      toggleEmailSendingSpinner(true);
      setTimeout(() => {
        toggleEmailSendingSpinner(false);
        setEmailValidation(true);
        handleClose(); 
        openNotification();
      }, 1000);
    }
  };

  const [notificationOpen, toggleNotification] = React.useState(false);
  const openNotification = () => toggleNotification(true);
  const closeNotification = () => toggleNotification(false);

  const t = useTranslations()

  return (
    <>
    <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} style={{width: "30rem", marginTop: ".6rem"}} open={notificationOpen} autoHideDuration={3000} onClose={closeNotification}>
      <Alert variant="filled" onClose={closeNotification} severity="success" style={{ width: '100%', fontSize: 18, height: "5rem", backgroundColor: "#3CDCAA" }}>
        { translate("PROFESSIONAL_EMAIL_SENT_NOTIFICATION", t) }
      </Alert>
    </Snackbar>
      {
        displayButton 
        ? <Button onClick={handleOpen} variant="contained" style={{marginTop: "1.5rem"}}>{ translate("PROFESSIONAL_SEND_RECOMMENDATIONS_TO_CLIENT", t) }</Button>
        : <></>
      }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h3" style={{marginBottom: "3rem", textAlign: "center"}}>
            {header}
          </Typography>
          {
            sendingEmail ?
              <Container align="center" style={{height: "12.2rem"}}>
                <CircularProgress style={{marginTop: "2rem"}} />
              </Container>
            :
            <>
              <TextField 
                value={enteredEmail} 
                error={isEmailValid === false} 
                label={ translate("SHARE_EMAIL", t) }
                variant="outlined" 
                fullWidth 
                required 
                onChange={(event) => setEmail(event.target.value)}/>
              <Grid container spacing={0} style={{marginTop: "2rem"}}>
                <Grid item md={6} xs={6}>
                  <Button onClick={handleClose} variant="outlined">{ translate("BUTTON_CANCEL", t) }</Button>
                </Grid>
                <Grid item md={6} xs={4}>
                  <Button style={{float: "right"}} onClick={() => {sendEmail(enteredEmail)}} variant="contained" color="primary">{ translate("BUTTON_SEND", t) }</Button>
                </Grid>
              </Grid>
            </>
          }

        </Box>
      </Modal>
    </>
  );
}
export default SendModal;