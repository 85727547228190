import { Button, Grid } from "@material-ui/core";
import { translate, useTranslations } from "pact.drural.app-skeleton";
import { isMobile as useIsMobile } from "pact.drural.utils";
import React from "react";

export interface StorefrontNavigationProps {
  isBackEnabled: boolean;
  isContinueEnabled: boolean;
  hideBackButton?: boolean;
  hideContinueButton?: boolean;
  onContinue: () => void;
  onBack: () => void;
}

export const StorefrontNavigation = (props: StorefrontNavigationProps) => {
  const t = useTranslations();
  const isMobile = useIsMobile();

  return (
    <Grid container style={{ marginTop: "4rem" }}>
      <Grid item md={6} xs={6}>
        {!props.hideBackButton && (
          <Button
            disabled={!props.isBackEnabled}
            variant="outlined"
            color="secondary"
            style={{ width: "22rem", marginLeft: isMobile ? 0 : "14.5rem" }}
            onClick={() => props.onBack()}
          >
            {translate("BUTTON_BACK", t)}
          </Button>
        )}
      </Grid>
      <Grid item md={5} xs={6}>
        {!props.hideContinueButton && (
          <div style={{ float: "right" }}>
            <Button
              disabled={!props.isContinueEnabled}
              variant="contained"
              color="primary"
              style={{ width: "22rem" }}
              onClick={() => props.onContinue()}
            >
              {translate("BUTTON_NEXT", t)}
            </Button>
          </div>
        )}
      </Grid>
      {!isMobile && <Grid item md={2} />}
    </Grid>
  );
};
