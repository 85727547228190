import * as React from "react";
import { connect } from "react-redux";

import {
  AuthenticatedAppSkeleton,
  StorefrontAppSkeleton
} from "pact.drural.app-skeleton";
import { getUrlParameter } from "pact.drural.utils";

import Entry from "./../components/questionnaire/Entry";
import QuestionnaireIntro from "./../components/questionnaire/QuestionnaireIntro";
import Questionnaire from "./../components/questionnaire/Questionnaire";
import QuestionaireResults from "./../components/questionnaire/QuestionaireResults";

import { StorefrontNavigation } from "./../components/storefront/StorefrontNavigation";

import {
  loadAnsweredQuestionnaireByToken,
  loadQuestionnaire,
  moveToStep,
  nextQuestion,
  persistQuestionnaire,
  previousQuestion
} from "./../actions/questionnaireActions";
import {
  validateLegalAgreements,
  validateQuestionnaireSection
} from "./../actions/validationActions";
import LegalAgreements from "./../components/questionnaire/LegalAgreements";
import { SideBarMenuItemCollection } from "./../components/navigation/SideBarMenuItemCollection";

const Skeleton = props => {
  return props.isProfessional ? (
    <AuthenticatedAppSkeleton menuItems={SideBarMenuItemCollection}>
      {props.children}
    </AuthenticatedAppSkeleton>
  ) : (
    <StorefrontAppSkeleton>{props.children}</StorefrontAppSkeleton>
  );
};

class PositiveHealth extends React.Component {
  componentDidMount() {
    if (
      this.props.questionnaire.questionnaireId === undefined ||
      this.props.questionnaire.questionnaire === null
    ) {
      this.props.loadQuestionnaire(
        this.props.questionnaireId,
        this.props.questionnaireToken
      );
    }
  }

  componentDidUpdate() {
    if (
      this.props.questionnaire.currentSectionIndex !== 0 &&
      this.props.questionnaire.currentStep === ""
    ) {
      this.props.loadQuestionnaire(
        this.props.questionnaire.questionnaireId,
        this.props.questionnaireToken
      );
    }
  }

  render() {
    const isProfessional = getUrlParameter("isp") !== null;
    const isShared = getUrlParameter("shared") !== null;
    const hasToken = getUrlParameter("token") !== null;

    switch (this.props.questionnaire.currentStep) {
      case "entry":
        return (
          <Skeleton isProfessional={isProfessional}>
            <Entry />
            {!isProfessional && (
              <StorefrontNavigation
                isBackEnabled={false}
                hideBackButton={true}
                isContinueEnabled={true}
                onContinue={() => this.props.moveToStep("questionnaireintro")}
                onBack={() => {}}
              />
            )}
          </Skeleton>
        );
      case "questionnaireintro":
        return (
          <Skeleton isProfessional={isProfessional}>
            <QuestionnaireIntro />
            {!isProfessional && (
              <StorefrontNavigation
                isBackEnabled={true}
                hideBackButton={true}
                isContinueEnabled={true}
                onBack={() => this.props.moveToStep("entry")}
                onContinue={() => this.props.moveToStep("questionnaire")}
              />
            )}
          </Skeleton>
        );
      case "questionnaire":
        return (
          <Skeleton isProfessional={isProfessional}>
            <Questionnaire />
            {!isProfessional && (
              <StorefrontNavigation
                isBackEnabled={true}
                isContinueEnabled={true}
                onBack={() => {
                  this.props.currentSectionIndex === 0
                    ? this.props.moveToStep("entry")
                    : this.props.previousQuestion();
                }}
                onContinue={() => {
                  this.props.hasFollowingSection
                    ? this.props.validateAndContinue(
                        this.props.currentSection,
                        this.props.nextQuestion
                      )
                    : this.props.validateAndContinue(
                        this.props.currentSection,
                        () => {
                          hasToken
                            ? this.props.moveToStep("questionnairelegal")
                            : this.props.moveToStep("questionnaireresult");
                        }
                      );
                }}
              />
            )}
          </Skeleton>
        );
      case "questionnairelegal":
        return (
          <Skeleton isProfessional={isProfessional}>
            <LegalAgreements />
            {!isProfessional && (
              <StorefrontNavigation
                isBackEnabled={true}
                isContinueEnabled={
                  this.props.questionnaire.questionnaire.legalAgreements[0]
                    .isChecked
                }
                onBack={() => this.props.moveToStep("questionnaire")}
                onContinue={() => {
                  this.props.validateLegalAndContinue(
                    this.props.questionnaire.questionnaire.legalAgreements,
                    () => {
                      this.props.persistQuestionnaire(
                        this.props.answeredQuestionnaireId,
                        this.props.questionnaire,
                        this.props.questionnaireToken
                      );

                      this.props.moveToStep("questionnaireresult");
                    }
                  );
                }}
              />
            )}
          </Skeleton>
        );
      case "questionnaireresult":
        return (
          <Skeleton isProfessional={isProfessional}>
            <QuestionaireResults />
            {!isProfessional && !isShared && (
              <StorefrontNavigation
                isBackEnabled={true}
                hideBackButton={isProfessional && this.props.questionnaireToken}
                isContinueEnabled={false}
                hideContinueButton={true}
                onBack={() =>
                  hasToken
                    ? this.props.moveToStep("questionnairelegal")
                    : this.props.moveToStep("questionnaire")
                }
                onContinue={() =>
                  this.props.questionnaire.recommendations.patients
                    ? this.props.moveToStep("recommendations")
                    : this.props.moveToStep("")
                }
              />
            )}
          </Skeleton>
        );
      default:
        return (
          <Skeleton isProfessional={isProfessional}>
            <Entry />
            {!isProfessional && (
              <StorefrontNavigation
                isBackEnabled={false}
                hideBackButton={true}
                isContinueEnabled={true}
                onContinue={() => this.props.moveToStep("questionnaireintro")}
                onBack={() => {}}
              />
            )}
          </Skeleton>
        );
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    questionnaire: state.questionnaire,
    questionnaireId: "96177a05-5a53-458f-a9f7-da03d9f4cbd6",
    questionnaireToken: state.questionnaire.token,
    currentSectionIndex: state.questionnaire.currentSectionIndex,
    answeredQuestionnaireId: state.questionnaire.answeredQuestionnaireId,
    currentSection:
      state.questionnaire.questionnaire?.sections[
        state.questionnaire.currentSectionIndex
      ],
    hasFollowingSection:
      state.questionnaire.questionnaire?.sections[
        state.questionnaire.currentSectionIndex + 1
      ] !== undefined
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadQuestionnaire: (questionnaireId, token) => {
      if (token) {
        dispatch(loadAnsweredQuestionnaireByToken(questionnaireId, token));
      } else {
        dispatch(loadQuestionnaire(questionnaireId));
      }
    },
    moveToStep: step => {
      dispatch(moveToStep(step));
    },
    validateAndContinue: (section, onSuccess) => {
      dispatch(validateQuestionnaireSection(section, onSuccess));
    },
    validateLegalAndContinue: (legal, onSuccess) => {
      dispatch(validateLegalAgreements(legal, onSuccess));
    },
    nextQuestion: () => {
      dispatch(nextQuestion());
      window.scrollTo(0, 0);
    },
    previousQuestion: () => {
      dispatch(previousQuestion());
      window.scrollTo(0, 0);
    },
    persistQuestionnaire: (answeredQuestionnaireId, questionnaire, token) => {
      dispatch(
        persistQuestionnaire(
          questionnaire.questionnaire.id,
          JSON.stringify(questionnaire.questionnaire),
          answeredQuestionnaireId,
          token
        )
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PositiveHealth);
