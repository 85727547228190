import { Container, Typography } from '@material-ui/core'
import * as React from 'react'
import { connect } from 'react-redux'
import { setNavigationOptions } from 'pact.drural.app-skeleton';
import SendModal from '../elements/Questionnaire/SendModal'
import { toggleSendModal } from '../../actions/modalActions'

import { moveToStep } from '../../actions/questionnaireActions';
import { FilterableServiceList } from 'pact.drural.ui';
import { UserRole } from '../../enums/UserRole';

const NavigationOptions = (props) => {
    return {
        isBackEnabled: true,
        isContinueEnabled: true, 
        onBackClick: () =>  props.moveToStep("questionnaireresult"),
        onContinueClick:() =>  props.moveToStep("referclient"),
        continueButtonText: "Refer Client",
        isPrintable: true
    }
} 

class RecommendationList extends React.Component {
    componentDidMount() {
        this.props.setNavigation(NavigationOptions(this.props));
        this.props.loadServices();
    }

    render() {
        return(
            <Container align="center">
                <h1>Recommendation List</h1>
                <Typography variant="subtitle1" style={{fontSize: 14}}>This a list of services recommended based on the results of the Positive Health questionanire</Typography>
                {
                    this.props.userRole === UserRole.Professionel 
                    ?
                        <>
                            <Typography 
                                style={{display: "none", fontSize: 14, margin: "2rem"}} 
                                className= "printer-display-static" 
                                variant="body1"
                                >
                                    Recommendations for: <b> {this.props.client.firstName} {this.props.client.lastName} </b>
                            </Typography>
                        </>
                    :
                        <></>
                }
                
                <Typography variant="body1" className="hide-on-print" style={{marginTop: "4rem", marginBottom: "2rem"}}>Click on a service for a detailed description. You can also refer your customer to directly to a specific provider (optional).</Typography>
                
                <FilterableServiceList />
                
                <Container className="hide-on-print" align="right" style={{marginTop: "4rem"}}>
                    <SendModal 
                        handleOpen={this.props.toggleSendModal} 
                        handleClose={this.props.toggleSendModal} 
                        open={this.props.isSendModalOpen} 
                    />
                </Container>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userRole: state.client.userRole,
        client: state.client.client,
        isSendModalOpen: state.modal.isSendModalOpen
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNavigation: (options) => {
            dispatch(setNavigationOptions(options))
        },
        toggleSendModal: () => {
            dispatch(toggleSendModal())
        },
        moveToStep: (step) => {
            dispatch(moveToStep(step))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationList)