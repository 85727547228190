import { StorefrontAppSkeleton } from "pact.drural.app-skeleton";
import React from "react";

export const GDPRAgreement = () => {
  return (
    <StorefrontAppSkeleton>
      <h1 style={{ marginBottom: "4rem" }}>
        Toestemming voor Gegevensverwerking
      </h1>

      <p style={{ marginBottom: "5rem" }}>
        <h4 style={{ marginBottom: "0" }}>Inleiding</h4> PACT Care B.V. (Pact
        Care) is een bedrijf gevestigd in Nederland. We zijn gevestigd aan
        Herengracht 420, 1017BZ, Amsterdam.
        <br />
        Pact Care vindt het belangrijk om zorgvuldig om te gaan met uw gegevens
        en wij respecteren uw privacy. Dit beleid bepaalt de basis waarop alle
        persoonlijke gegevens die wij van u verzamelen, of die u aan ons
        verstrekt, door ons verwerkt worden. Lees het volgende zorgvuldig door
        om onze standpunten en praktijken te begrijpen betreffende uw
        persoonlijke gegevens en hoe wij deze zullen behandelen.
      </p>

      <p style={{ marginBottom: "5rem" }}>
        <h4 style={{ marginBottom: "0" }}>Doel van de Verwerking</h4> U staat op
        het punt om een dienst te gebruiken die ontwikkeld is voor Stichting
        Rijnstate Ziekenhuis (Rijnstate) door Pact Care, waarbij u de positieve
        gezondheidsvragenlijst Vragenlijst invult. De antwoorden die u geeft,
        zullen worden verwerkt door een vooraf getraind model gebaseerd op
        kunstmatige intelligentie (AI) om gepersonaliseerde gezondheids- en
        sociale zorgdiensten te generen die u ten goede kunnen komen, zoals
        beweegprogramma's, voedingsadviezen of sociale ondersteuningsopties.
      </p>

      <p style={{ marginBottom: "5rem" }}>
        <h4 style={{ marginBottom: "0" }}>Welke Gegevens We Verzamelen</h4> De
        persoonlijke gegevens die via de positieve gezondheids Vragenlijst
        worden verzameld, omvatten:
        <ul>
          <li>
            Reacties op gezondheidsgerelateerde vragen (bijv. fysieke,
            emotionele en sociale welzijn).
          </li>
          <li>
            Eventuele aanvullende persoonlijke informatie die u verstrekt en
            relevant is voor uw welzijnsbehoeften.
          </li>
        </ul>
      </p>

      <p style={{ marginBottom: "5rem" }}>
        <h4 style={{ marginBottom: "0" }}>Hoe We Uw Gegevens Gebruiken</h4> Uw
        gegevens zullen worden gebruikt om:
        <ul>
          <li>
            Uw gezondheidsprofiel te analyseren op basis van de antwoorden op de
            vragenlijst.
          </li>
          <li>
            Gepersonaliseerde aanbevelingen voor gezondheids- en sociale
            zorgdiensten te genereren.
          </li>
          <li>
            De effectiviteit van de aangeboden diensten te verbeteren door
            middel van voortdurende training en verfijning van onze AI-modellen.
          </li>
        </ul>
      </p>

      <p style={{ marginBottom: "5rem" }}>
        <h4 style={{ marginBottom: "0" }}>Delen van Uw Gegevens</h4> Uw
        persoonlijke gegevens kunnen worden gedeeld met:
        <ul>
          <li>
            Rijnstate, als onze klant, om u van op maat gemaakte
            gezondheidszorgdiensten te voorzien.
          </li>
          <li>
            Derde partijen serviceproviders, onder strikte
            vertrouwelijkheidsakkoorden, die ons helpen bij het leveren van onze
            dienste (bijv. Microsoft Azure).
          </li>
        </ul>
      </p>

      <p style={{ marginBottom: "5rem" }}>
        <h4 style={{ marginBottom: "0" }}>Wettelijke Basis voor Verwerking</h4>{" "}
        De verwerking van uw persoonlijke gegevens is gebaseerd op uw expliciete
        toestemming, die u op elk moment kunt intrekken. Let op dat het
        intrekken van toestemming onze mogelijkheid kan beïnvloeden om bepaalde
        diensten aan u te leveren.
      </p>

      <p style={{ marginBottom: "5rem" }}>
        <h4 style={{ marginBottom: "0" }}>
          Gegevensbeveiliging en -bewaartermijn
        </h4>{" "}
        We implementeren passende technische en organisatorische maatregelen om
        uw persoonlijke gegevens te beschermen tegen ongeautoriseerde of
        onwettige verwerking en tegen onopzettelijk verlies, vernietiging of
        schade:
        <ul>
          <li>Gegevensversleuteling tijdens verzending en in rust</li>
          <li>
            Gegevensbewaarbeleid zorgt ervoor dat uw gegevens alleen worden
            bewaard zolang nodig is voor de doeleinden die in dit beleid zijn
            uiteengezet.
            <ul>
              <li>
                Gebruikersresponsen: De reacties op de Positive Health
                Vragenlijst worden niet langer dan 12 maanden bewaard, om
                adequate opvolging van de gegeven zorgaanbevelingen mogelijk te
                maken.
              </li>
              <li>
                Accountinformatie: Als u een account aanmaakt, worden uw
                accountgegevens bewaard zolang uw account actief blijft. U kunt
                op elk moment om verwijdering van uw account verzoeken, waarna
                uw gegevens volgens onze verwijderingsprocedures snel worden
                verwijderd.
              </li>
              <li>
                AI Trainingsdata: Geanonimiseerde gegevens die worden gebruikt
                voor het trainen van ons AI-model worden gescheiden van
                persoonlijke identifiers bewaard en zolang als nodig behouden
                voor continue training en verbetering van onze AI-capaciteiten.
              </li>
            </ul>
          </li>
        </ul>
      </p>

      <p style={{ marginBottom: "5rem" }}>
        <h4 style={{ marginBottom: "0" }}>Uw Rechten</h4> Onder de AVG hebt u
        rechten met betrekking tot uw persoonlijke gegevens en de verwerking
        daarvan, waaronder:
        <ul>
          <li>Het recht om toegang te krijgen tot uw persoonlijke gegevens.</li>
          <li>Het recht om onjuiste gegevens te corrigeren.</li>
          <li>Het recht om uw gegevens te wissen.</li>
          <li>
            Het recht om verwerking te beperken of daartegen bezwaar te maken.
          </li>
          <li>Het recht op gegevensoverdraagbaarheid.</li>
          <li>Het recht om uw toestemming in te trekken?</li>
        </ul>
        Voor meer informatie over deze rechten, of als u een van deze rechten
        wilt uitoefenen, neem dan contact op met onze functionaris voor
        gegevensbescherming via info@pact.care
      </p>

      <p style={{ marginBottom: "5rem" }}>
        <h4 style={{ marginBottom: "0" }}>Toestemming</h4> Door op "Ik ga
        akkoord" te klikken, erkent u dat u dit toestemmingsformulier hebt
        gelezen en begrepen.oor op "Ik ga akkoord" te klikken, erkent u dat u
        dit toestemmingsformulier hebt gelezen en begrepen en gaat u akkoord met
        de verzameling en verwerking van uw persoonlijke gegevens zoals
        hierboven beschreven.
      </p>
    </StorefrontAppSkeleton>
  );
};
