import * as React from 'react'

import { Typography, Grid } from '@material-ui/core';

const PrintableAnswers = ({questionnaire}) => {
    return(
        <div style={{ display: "none" }} className="printer-display-static">
            <Grid container spacing={4} align="left" className="printer-padding printer-display-static" style={{marginTop: 0}}>
                {
                questionnaire.map((section, i) => {
                    return(
                        <Grid item md={6} xs={12} key={i} style={{pageBreakBefore: i % 3 === 0 ? "always" : "", marginTop: i % 3 === 0 ? "5rem" : ""}} >
                          <Typography variant="h6" style={{marginBottom: ".5rem"}}>{section.header}</Typography>
                          {
                            section.questions.map((question, i) => {
                              let bgColor = i % 2 !== 0 ? "#FFFFFF" : "#E0DEE3"
                              return (
                                <Grid container key={i}>
                                  <Grid item md={9} xs={9} style={{backgroundColor: bgColor}}>
                                    <Typography style={{marginLeft: "1rem"}} variant="body2">{question.text}</Typography>
                                  </Grid>
                                  <Grid item md={2} xs={2} style={{backgroundColor: bgColor, textAlign: "right"}}>
                                    <Typography variant="body2"><b>{
                                      question.type === 0
                                      ? question.selectedValue
                                      : question.isOtherOptionSelected
                                        ? question.otherOptionInput
                                        : question.options.find(o => o.isSelected) !== undefined ? question.options.find(o => o.isSelected).text : ""
                                    }</b></Typography>
                                  </Grid>
                                  <Grid md={1} xs={false}  style={{backgroundColor: bgColor}} />
                                </Grid>
                              )
                            })
                          }
                        </Grid>
                      )
                })
                }
            </Grid>
        </div>
    )
}

export default PrintableAnswers