import {
  Checkbox,
  Container,
  Grid,
  LinearProgress,
  Typography
} from "@material-ui/core";
import {
  acceptLegalAgreement,
  moveToStep,
  persistQuestionnaire
} from "../../actions/questionnaireActions";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setNavigationOptions } from "pact.drural.app-skeleton";
import { defaultTextColor, validationErrorTextColor } from "../../utils/config";

const NavigationOptions = props => {
  return {
    isBackEnabled: true,
    isContinueEnabled: props.questionnaire.legalAgreements[0].isChecked,
    onBackClick: () => props.moveToStep("questionnaire"),
    onContinueClick: () => {
      props.persistQuestionnaire(
        props.answeredQuestionnaireId,
        props.questionnaire,
        props.questionnaireToken
      );
      props.moveToStep("questionnaireresult");
    }
  };
};

const LegalAgreements = props => {
  useEffect(() => {
    props.setNavigation(NavigationOptions(props));
  });

  return (
    <Container align="center">
      <Container style={{ minHeight: "40rem" }}>
        <LinearProgress
          variant="determinate"
          value={100}
          style={{ marginBottom: "3rem", height: 10 }}
        />
        <h1 style={{ marginBottom: "4rem" }}>Toestemming voor gebruik</h1>

        {props.questionnaire.legalAgreements.map((agreement, i) => {
          let headerColor = !props.validationSummary.every(
            s => s.id !== agreement.id
          )
            ? validationErrorTextColor
            : defaultTextColor;

          return (
            <Grid key={i} container style={{ marginBottom: "3rem" }}>
              <Grid item md={1} xs={1}>
                <Checkbox
                  checked={agreement.isChecked}
                  onClick={() => props.acceptLegalAgreement(i)}
                />
              </Grid>
              <Grid item md={11}>
                <div
                  style={{
                    float: "left",
                    textAlign: "left",
                    paddingTop: ".75rem"
                  }}
                >
                  <Typography
                    style={{
                      color: headerColor,
                      fontWeight: 400,
                      marginBottom: "2rem"
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: agreement.text }} />
                  </Typography>
                </div>
              </Grid>
            </Grid>
          );
        })}
      </Container>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    questionnaire: state.questionnaire.questionnaire,
    validationSummary: state.validation.legal,
    answeredQuestionnaireId: state.questionnaire.answeredQuestionnaireId,
    questionnaireToken: state.questionnaire.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    acceptLegalAgreement: index => {
      dispatch(acceptLegalAgreement(index));
    },
    setNavigation: options => {
      dispatch(setNavigationOptions(options));
    },
    moveToStep: step => {
      dispatch(moveToStep(step));
    },
    persistQuestionnaire: (answeredQuestionnaireId, questionnaire, token) => {
      dispatch(
        persistQuestionnaire(
          questionnaire.id,
          JSON.stringify(questionnaire),
          answeredQuestionnaireId,
          token
        )
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalAgreements);
