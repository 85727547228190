import { RAISE_ERROR } from "../actions/errorActions";

export interface ErrorState {
  error: any | null;
}

const defaultState = {
  error: null
} as ErrorState;

const errorReducer = (state = defaultState, action) => {
  switch (action.type) {
    case RAISE_ERROR:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};

export default errorReducer;
