import { getToken, toggleBackdropSpinner } from "pact.drural.app-skeleton";
import { raiseError } from "./errorActions";
import { setClient } from "./clientActions";

export const NEXT_QUESTION = "NEXT_QUESTION";
export const PREVIOUS_QUESTION = "PREVIOUS_QUESTION";
export const SELECT_VALUE = "SELECT_VALUE";
export const ENTER_OTHER_VALUE = "ENTER_OTHER_VALUE";
export const SET_ANSWER_ID = "SET_ANSWER_ID";

export const SET_QUESTIONNAIRE = "SET_QUESTIONNAIRE";
export const SET_QUESTIONNAIRE_LIST = "SET_QUESTIONNAIRE_LIST";

export const MOVE_TO_STEP = "MOVE_TO_STEP";

export const ACCEPT_LEGAL_AGREEMENT = "ACCEPT_LEGAL_AGREEMENT";

export const acceptLegalAgreement = index => ({
  type: ACCEPT_LEGAL_AGREEMENT,
  index
});

export const nextQuestion = selectedValue => ({
  type: NEXT_QUESTION,
  selectedValue
});

export const previousQuestion = () => ({
  type: PREVIOUS_QUESTION
});

export const selectValue = (sectionIndex, questionIndex, value) => ({
  type: SELECT_VALUE,
  sectionIndex: sectionIndex,
  questionIndex: questionIndex,
  value: value
});

const setQuestionnaire = questionnaire => ({
  type: SET_QUESTIONNAIRE,
  questionnaire
});

const setQuestionnaireList = questionnaires => ({
  type: SET_QUESTIONNAIRE_LIST,
  questionnaires
});

export const enterOtherValue = (sectionIndex, questionIndex, value) => ({
  type: ENTER_OTHER_VALUE,
  sectionIndex: sectionIndex,
  questionIndex: questionIndex,
  value: value
});

export const loadQuestionnaire =
  (questionnaireId: string) => (dispatch: any) => {
    dispatch(toggleBackdropSpinner(true));

    return fetch(
      process.env.REACT_APP_PACT_GATEWAY_URI +
        "v1/questionnaire/" +
        questionnaireId,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(response => {
        if (response.status !== 200) {
          dispatch(raiseError(response));
        } else {
          return response.json();
        }
      })
      .then(json => {
        dispatch(setQuestionnaire(json.questionnaire));
        dispatch(toggleBackdropSpinner(false));
      })
      .catch(error => dispatch(raiseError(error)));
  };

export const loadAnsweredQuestionnaireByToken =
  (questionnaireId: string, token: string) => (dispatch: any) => {
    dispatch(toggleBackdropSpinner(true));

    return fetch(
      process.env.REACT_APP_PACT_GATEWAY_URI +
        "v1/questionnaire/token/" +
        token,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        }
      }
    )
      .then(response => {
        if (response.status !== 200) {
          dispatch(raiseError(response));
        } else {
          return response.json();
        }
      })
      .then(json => {
        if (json.questionnaire) {
          dispatch(setQuestionnaire(JSON.parse(json.questionnaire.payload)));
          dispatch(setAnswerId(json.questionnaire.id));
          dispatch(setClient(json.metadata));
          dispatch(moveToStep("questionnaireresult"));
          dispatch(toggleBackdropSpinner(false));
        } else {
          dispatch(loadQuestionnaire(questionnaireId));
        }
      });
    // .catch(error => dispatch(raiseError(error)));
  };

export const loadQuestionnaireList = () => (dispatch: any) => {
  dispatch(toggleBackdropSpinner());

  return getToken()
    .then(token => {
      return fetch(
        process.env.REACT_APP_PACT_GATEWAY_URI + "v1/questionnaire/list",
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          }
        }
      )
        .then(response => {
          if (response.status !== 200) {
            dispatch(raiseError(response));
          } else {
            return response.json();
          }
        })
        .then(json => {
          dispatch(setQuestionnaireList(json));
          dispatch(toggleBackdropSpinner());
        });
    })
    .catch(error => dispatch(raiseError(error)));
};

export const shareQuestionnaire =
  (email: string, questionnaireToken: string) => (dispatch: any) => {
    if (!questionnaireToken) {
      return Promise.resolve();
    }

    dispatch(toggleBackdropSpinner());

    return getToken()
      .then(token => {
        return fetch(
          process.env.REACT_APP_PACT_GATEWAY_URI + "v1/questionnaire/share",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token
            },
            body: JSON.stringify({
              token: questionnaireToken,
              email: email
            })
          }
        )
          .then(response => {
            if (response.status !== 200) {
              dispatch(raiseError(response));
            } else {
              return response.json();
            }
          })
          .then(() => {
            dispatch(toggleBackdropSpinner());
          });
      })
      .catch(error => dispatch(raiseError(error)));
  };

export const persistQuestionnaire =
  (
    questionnaireId: string,
    payload: string,
    answerId: string,
    questionnaireToken?: string
  ) =>
  (dispatch: any) => {
    if (!questionnaireToken) {
      return Promise.resolve();
    }

    dispatch(toggleBackdropSpinner());

    return getToken()
      .then(token => {
        return fetch(
          process.env.REACT_APP_PACT_GATEWAY_URI +
            "v1/questionnaire/" +
            questionnaireId +
            "/save",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token
            },
            body: JSON.stringify({
              id: answerId,
              subject: "Experimental",
              payload: payload,
              token: questionnaireToken
            })
          }
        )
          .then(response => {
            if (response.status !== 200) {
              dispatch(raiseError(response));
            } else {
              return response.json();
            }
          })
          .then(json => {
            dispatch(setAnswerId(json.id));
            dispatch(toggleBackdropSpinner());
          });
      })
      .catch(error => dispatch(raiseError(error)));
  };

export const moveToStep = (step: string) => ({
  type: MOVE_TO_STEP,
  step
});

export const setAnswerId = (id: string) => ({
  type: SET_ANSWER_ID,
  id
});
