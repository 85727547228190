export const sliderConfig = {
  rangeMin: 0,
  rangeMax: 10,
  marks: [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" }
  ]
};
export const sliderConfigMobile = {
  rangeMin: 0,
  rangeMax: 10,
  marks: [
    { value: 0, label: "0" },
    { value: 1, label: "" },
    { value: 2, label: "" },
    { value: 3, label: "" },
    { value: 4, label: "" },
    { value: 5, label: "5" },
    { value: 6, label: "" },
    { value: 7, label: "" },
    { value: 8, label: "" },
    { value: 9, label: "" },
    { value: 10, label: "10" }
  ]
};

export const validationErrorTextColor = "#CC0000";
export const defaultTextColor = "#3D3D3D";

export const shouldUseExternalRecommendationEngine = (): boolean => {
  const useExternal = localStorage.getItem("useExternalEngine");

  return useExternal && useExternal === "true";
};
