import {
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  setNavigationOptions,
  translate,
  useTranslations
} from "pact.drural.app-skeleton";
import {
  setClientFirstName,
  setClientLastName
} from "../../actions/clientActions";
import { validateCustomer } from "../../actions/validationActions";
import { moveToStep } from "../../actions/questionnaireActions";
import { UserRole } from "../../enums/UserRole";

const NavigationOptions = props => {
  return {
    isBackEnabled: props.userRole !== UserRole.Client,
    isContinueEnabled: true,
    onBackClick:
      props.userRole === UserRole.Client
        ? () => {}
        : () => props.moveToStep("customer-selection"),
    onContinueClick: () => props.moveToStep("questionnaireintro")
  };
};

const Entry = props => {
  const t = useTranslations();

  useEffect(() => {
    props.setNavigation(NavigationOptions(props));
  }, [props]);

  if (props.questionnaire === null) {
    return <></>;
  }

  return (
    <Container align="center">
      <h1>{props.questionnaire.welcomePage.header}</h1>
      <Typography variant="subtitle1" style={{ fontSize: 14 }}>
        {props.questionnaire.welcomePage.subheader}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={2}></Grid>
        <Grid item md={8}>
          <Container align="left" style={{ marginTop: "2rem" }}>
            <Typography
              variant="body1"
              style={{ marginBottom: "2rem", marginTop: "4rem" }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: props.questionnaire.welcomePage.upperText
                }}
              />
            </Typography>
            <List>
              {props.questionnaire.welcomePage.bulletPoints.map((b, i) => (
                <ListItem style={{ marginBottom: "-1rem" }} key={i}>
                  <ListItemIcon>
                    <ArrowForward />
                  </ListItemIcon>
                  <ListItemText variant="body1">{b.text}</ListItemText>
                </ListItem>
              ))}
            </List>
            <Typography
              variant="body1"
              style={{ marginBottom: "2rem", marginTop: "4rem" }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: props.questionnaire.welcomePage.lowerText
                }}
              />
            </Typography>
          </Container>
        </Grid>
      </Grid>
      <Typography
        style={{ marginTop: "4rem", fontSize: "12px", fontWeight: 500 }}
      >
        {props.questionnaire.footerNote} (
        <a
          href={props.questionnaire.footerNoteLink}
          rel="noreferrer"
          target="_blank"
        >
          {props.questionnaire.footerNoteLinkText}
        </a>
        )
      </Typography>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    userRole: state.client.userRole,
    client: state.client.client,
    clientValidationError:
      state.validation.customer !== null && !state.validation.customer.isValid,
    questionnaire: state.questionnaire.questionnaire
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: options => {
      dispatch(setNavigationOptions(options));
    },
    validateAndContinue: (firstName, onSuccess) => {
      dispatch(validateCustomer(firstName, onSuccess));
    },
    moveToStep: step => {
      dispatch(moveToStep(step));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Entry);
