import {
  END_SAVE_QUESTIONNAIRE_ADVICE,
  SET_CATEGORIES,
  SET_QUESTIONNAIRE_ADVICES,
  SET_RECOMMENDATION_CATEGORIES,
  SET_RECOMMENDATION_IDS,
  SET_RECOMMENDATION_SUCCESS,
  START_LOAD_CATEGORIES,
  START_LOAD_QUESTIONNAIRE_ADVICES,
  START_RECOMMENDATION_ENGINE,
  START_RECOMMENDATION_REQUEST,
  START_SAVE_QUESTIONNAIRE_ADVICE
} from "../actions/recommendationActions";

const defaultState = {
  categories: [],
  advices: null,
  availableCategories: null,
  flowId: "",
  executionId: "",
  isLoadingAvailableCategories: false,
  isSavingQuestionnaireAdvice: false,
  isLoadingAdvices: false,
  isLoading: false,
  isEngineRunning: false,
  isRequestInflight: false
};

const recommendationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case START_LOAD_QUESTIONNAIRE_ADVICES:
      return {
        ...state,
        isLoadingAdvices: true
      };
    case SET_QUESTIONNAIRE_ADVICES:
      return {
        ...state,
        isLoadingAdvices: false,
        advices: action.advices
      };
    case START_SAVE_QUESTIONNAIRE_ADVICE:
      return {
        ...state,
        isSavingQuestionnaireAdvice: true
      };
    case END_SAVE_QUESTIONNAIRE_ADVICE:
      return {
        ...state,
        isSavingQuestionnaireAdvice: false
      };
    case START_LOAD_CATEGORIES:
      return {
        ...state,
        isLoadingAvailableCategories: true,
        availableCategories: []
      };
    case SET_CATEGORIES:
      return {
        ...state,
        isLoadingAvailableCategories: false,
        availableCategories: action.categories
      };
    case START_RECOMMENDATION_REQUEST:
      return {
        ...state,
        isRequestInflight: !state.isRequestInflight
      };
    case START_RECOMMENDATION_ENGINE:
      return {
        ...state,
        isLoading: true,
        isEngineRunning: true
      };
    case SET_RECOMMENDATION_IDS:
      return {
        ...state,
        executionId: action.executionId,
        flowId: action.flowId
      };
    case SET_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        isEngineRunning: false,
        isRequestInflight: false
      };
    case SET_RECOMMENDATION_CATEGORIES:
      return {
        ...state,
        isLoading: false,
        isRequestInflight: false,
        categories: action.categories
      };
    default:
      return state;
  }
};

export default recommendationReducer;
