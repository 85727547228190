import { Container, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, LinearProgress } from '@material-ui/core';
import * as React from 'react'
import { connect } from 'react-redux'
import { setNavigationOptions } from 'pact.drural.app-skeleton';
import { ArrowForward } from '@material-ui/icons'
import { moveToStep } from '../../actions/questionnaireActions';

const NavigationOptions = (props) => {
    return {
        isBackEnabled: true,
        isContinueEnabled: true, 
        onBackClick: () => props.moveToStep("entry"),
        onContinueClick: () => props.moveToStep("questionnaire") 
    }
}

class QuestionnaireIntro extends React.Component {
    componentDidMount() {
        if (this.props.questionnaire.introPage === null) {
            this.props.moveToStep("questionnaire") 
        }

        this.props.setNavigation(NavigationOptions(this.props))
    }

    render() {
        if (this.props.questionnaire === null || this.props.questionnaire.introPage === null) {
            return <></>
        }
        
        return(
            <Container align="center">
                <LinearProgress variant="determinate" value={0} style={{marginBottom: "3rem", height: 10}} />
                <h1>{this.props.questionnaire.introPage.header}</h1>
                <Typography variant="subtitle1" style={{fontSize: 14}}>{this.props.questionnaire.introPage.subheader}</Typography>
                <Grid container spacing={2} style={{marginTop: "4rem"}}>
                    <Grid item md={2}></Grid>
                    <Grid item md={8}>
                        <Container align="left">
                            <Typography variant="body1" style={{marginBottom: "2rem"}}>
                                <div dangerouslySetInnerHTML={{_html: this.props.questionnaire.introPage.upperText}} />
                            </Typography>
                            <List>
                            {
                                    this.props.questionnaire.introPage.bulletPoints.map((b, i) => 
                                        <ListItem style={{marginBottom: "-1rem"}} key={i}>
                                            <ListItemIcon>
                                                <ArrowForward />
                                            </ListItemIcon>
                                            <ListItemText variant="body1">{b.text}</ListItemText>
                                        </ListItem>
                                    )
                                }
                            </List>
                            <Typography variant="body1" style={{marginBottom: "2rem", marginTop: "4rem"}}>{this.props.questionnaire.welcomePage.lowerText}</Typography>
                        </Container>
                    </Grid>
                </Grid>
                <Typography 
                    style={{marginTop: "8rem", fontSize: "12px", fontWeight: 500}}>
                        {this.props.questionnaire.footerNote} (<a href={this.props.questionnaire.footerNoteLink} rel="noreferrer" target="_blank">{this.props.questionnaire.footerNoteLinkText}</a>)
                </Typography>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        questionnaire: state.questionnaire.questionnaire
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setNavigation: (options) => {
            dispatch(setNavigationOptions(options))
        },
        moveToStep: (step) => {
            dispatch(moveToStep(step))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireIntro)