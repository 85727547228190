import { Typography, Box } from "@material-ui/core";
import * as React from "react"

const ResultBar = ({header, score, maxScore, color}) => {
    return(
        <Box
            style={{ 
                marginBottom: "1rem",
                borderRadius: 5,
            }} 
            sx={{
                '&:hover': {
                    backgroundColor: '#E0DEE3'
                }}}>
            <Typography variant="subtitle1" style={{paddingLeft: "1rem"}}>{header}</Typography>
            <Box width={score * 3 + "rem"} height="1rem" style={{backgroundColor: color, marginBottom: '-.4rem', marginLeft: "1rem"}}></Box>
            <Typography variant="h6" style={{fontSize: 14, paddingLeft: "1rem"}} >Mijn score: <b>{score.toFixed(0)}/{maxScore}</b></Typography>
        </Box>       
    )
}

export default ResultBar;