import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { createStore, applyMiddleware, compose  } from 'redux'
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'

import rootReducer from './reducers/rootReducer'

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';

import { AuthenticatedApolloClient, AuthenticatedAppSkeleton, Entrypoint } from 'pact.drural.app-skeleton'
import { DruralThemeProvider } from 'pact.drural.ui';
import App from './App';
import { SideBarMenuItemCollection } from './components/navigation/SideBarMenuItemCollection';
import QuestionnaireList from './components/QuestionnaireList';
import PositiveHealth from './pages/PositiveHealth';
import { ApolloProvider } from '@apollo/client';
import { GDPRAgreement } from './pages/GDPRAgreement';

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
     : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunkMiddleware),
);

const store = createStore(
  rootReducer, 
  enhancer
);

const client = AuthenticatedApolloClient.Instance();

ReactDOM.render(
  <Provider store={store}>
    <DruralThemeProvider>
      <ApolloProvider client={client}>
        <Router>
          <Routes>
            <Route exact path="/positive-health" element={<PositiveHealth />} />
            <Route exact path="/positive-health/gdpr" element={<GDPRAgreement />} />
            <Route exact path="/entry" element={<Entrypoint />} />
            <Route exact path="/questionnaire" element={<App />} />
            <Route exact path="/" element={<AuthenticatedAppSkeleton menuItems={SideBarMenuItemCollection}><QuestionnaireList /></AuthenticatedAppSkeleton>} />
          </Routes>
        </Router>
      </ApolloProvider>
    </DruralThemeProvider>
  </Provider>,
  document.getElementById('root')
);
