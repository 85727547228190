import {
  Container,
  Grid,
  Typography,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow
} from "@material-ui/core";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  setNavigationOptions,
  translate,
  useTranslations
} from "pact.drural.app-skeleton";

import RadarChart from "react-svg-radar-chart";
import "react-svg-radar-chart/build/css/index.css";
import ResultBar from "../elements/Questionnaire/ResultBar";
import AnswersModal from "../elements/Questionnaire/AnswersModal";
import { getUrlParameter, isMobile } from "pact.drural.utils";
import PrintableAnswers from "../elements/Questionnaire/PrintableAnswers";
import {
  acceptLegalAgreement,
  moveToStep,
  persistQuestionnaire,
  shareQuestionnaire
} from "../../actions/questionnaireActions";
import CategoryRecommendations from "../patient/CategoryRecommendations";
import { Email, Print } from "@material-ui/icons";
import { UserRole } from "../../enums/UserRole";
import { ConsentModal } from "../elements/Questionnaire/ConsentModal";
import { SpiderwebModal } from "../elements/Questionnaire/SpiderwebModal";
import ShareQuestionnaireModal from "../modals/ShareQuestionnaireModal";

const calculateScore = questions => {
  let score = 0;
  let count = 0;

  questions.forEach(question => {
    if (question.type === 0) {
      score += question.selectedValue;
      count += 1;
    }
  });

  return score / count;
};

const questionaireData = questionnaire => {
  let data = {};
  questionnaire.forEach((section, i) => {
    if (!section.isMeasurable) {
      return;
    }

    let propertyName = section.header.replace(/\s/g, "");
    propertyName = propertyName.charAt(0).toLowerCase() + propertyName.slice(1);

    data[propertyName] = calculateScore(section.questions) / 10;
  });

  return [
    {
      data: data,
      meta: { color: "blue" }
    }
  ];
};

const questionaireCaptions = questionnaire => {
  let captions = {};
  questionnaire.forEach((section, i) => {
    if (!section.isMeasurable) {
      return;
    }

    let propertyName = section.header.replace(/\s/g, "");
    propertyName = propertyName.charAt(0).toLowerCase() + propertyName.slice(1);

    captions[propertyName] = section.header;
  });

  return captions;
};

const genderFromEnum = gender => {
  switch (gender) {
    case 0:
      return "CUSTOMER_GENDER_MALE";
    case 1:
      return "CUSTOMER_GENDER_FEMALE";
    default:
      return "CUSTOMER_GENDER_OTHER";
  }
};

const NavigationOptions = (
  props,
  continueButtonText,
  setShareOpen,
  isShared
) => {
  const sharingOptions = [
    {
      onClick: () => {
        window.print();
      },
      translationKey: "SHARE_PRINT",
      icon: <Print />
    }
  ];

  if (props.questionnaire.legalAgreements[1].isChecked && !isShared) {
    sharingOptions.push({
      onClick: () => {
        setShareOpen(true);
      },
      translationKey: "SHARE_EMAIL",
      icon: <Email />
    });
  }

  return {
    isBackEnabled: true,
    isContinueEnabled: false,
    isContinueVisible: false,
    onBackClick: () => props.moveToStep("questionnairelegal"),
    onContinueClick:
      props.userRole === UserRole.Client
        ? () =>
            props.questionnaire.recommendations.patients
              ? props.moveToStep("recommendations")
              : props.moveToStep("")
        : () =>
            props.questionnaire.recommendations.professionals
              ? props.moveToStep("recommendationlist")
              : props.moveToStep(""),
    isPrintable: true,
    continueButtonText: continueButtonText,
    sharingOptions: sharingOptions
  };
};

// 0-3.3 Red; 3.4. - 6.7 yellow; 6.8 - 10 green
const getColorByScore = () => {
  return "#92d400";
};

const QuestionnaireResults = props => {
  const t = useTranslations();
  const isProfessional = getUrlParameter("isp") !== null;
  const isShared = getUrlParameter("shared") !== null;
  const hasToken = getUrlParameter("token") !== null;

  const [isShareOpen, setShareOpen] = useState(false);

  useEffect(() => {
    props.setNavigation(
      NavigationOptions(
        props,
        translate("BUTTON_FINISH", t),
        setShareOpen,
        isShared
      )
    );
  }, [props, t, setShareOpen, isShared]);

  let spiderWebFontSize = isMobile() ? 11 : 14;
  return (
    <Container align="center" style={{ marginBottom: "14rem" }}>
      {(isProfessional || isShared) && (
        <h1 align="left" style={{ marginBottom: "4rem" }}>
          {props.client?.firstname} {props.client?.lastname}{" "}
          {(props.client?.gender || props.client?.age !== -1) && (
            <>
              (
              {props.client?.gender && (
                <>{translate(genderFromEnum(props.client?.gender), t)} |</>
              )}
              {props.client?.age !== -1 && <>{props.client?.age} yrs</>})
            </>
          )}
        </h1>
      )}

      <h1>{translate("QUESTIONNAIRE_RESULTS_HEADER", t)}</h1>
      <Typography
        variant="subtitle1"
        style={{ fontSize: 14 }}
        className="hide-on-print"
      >
        {translate("QUESTIONNAIRE_RESULTS_SUBHEADER", t)}
      </Typography>
      {(isProfessional || isShared) && (
        <>
          {isProfessional && (
            <>
              <Typography
                align="left"
                variant="h4"
                style={{ marginTop: "5rem", marginBottom: "4rem" }}
              >
                Toestemming van de patiënt
              </Typography>
              <Grid container justifyContent="center">
                {props.questionnaire.legalAgreements.map((agreement, i) => {
                  return (
                    <Grid key={i} item md={4} xs={12}>
                      <Box
                        sx={{
                          backgroundColor: agreement.isChecked
                            ? "#92d400"
                            : "#882345",
                          padding: "1.2rem",
                          color: agreement.isChecked ? "black" : "white",
                          borderRadius: 8,
                          boxShadow: "4px 4px 16px rgba(0, 0, 0, 0.3)",
                          minHeight: "7rem",
                          height: "auto",
                          marginLeft: "1rem",
                          marginRight: "1rem",
                          marginBottom: "2rem"
                        }}
                      >
                        {agreement.shortText}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}

          <Typography
            variant="h4"
            align="left"
            style={{ marginTop: "5rem", marginBottom: "2.5rem" }}
          >
            Wensen & Werk
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableCell></TableCell>
                <TableCell>Vraag</TableCell>
                <TableCell>Antwoord</TableCell>
              </TableHead>
              <TableBody>
                {props.questionnaire.sections.map(section => {
                  if (
                    section.header === "Wensen" ||
                    section.header === "Werk"
                  ) {
                    return (
                      <>
                        {section.questions.map((question, k) => {
                          return (
                            <TableRow key={k}>
                              <TableCell></TableCell>
                              <TableCell width="40%">
                                <div style={{ marginRight: "4rem" }}>
                                  {question.text}
                                </div>
                              </TableCell>
                              <TableCell style={{ padding: "0" }}>
                                <div style={{ verticalAlign: "top" }}>
                                  <ul>
                                    {question.options
                                      .filter(o => o.isSelected)
                                      .map((option, l) => {
                                        return <li key={l}>{option.text}</li>;
                                      })}
                                    {question.isOtherOptionSelected && (
                                      <li>
                                        {question.otherOptionText}
                                        {": "}
                                        <b>{question.otherOptionInput}</b>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    );
                  }

                  return <></>;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <Grid container spacing={2} style={{ marginTop: "2rem" }}>
        <Grid item md={1} xs={1}></Grid>
        <Grid item md={4} xs={10} className="hide-on-print">
          <Container align="left">
            <h4>{translate("QUESTIONNAIRE_RESULTS_OVERVIEW", t)}</h4>
            {props.questionnaire.sections.map((section, i) => {
              if (!section.isMeasurable) {
                return <></>;
              }

              let score = calculateScore(section.questions);

              return (
                <ResultBar
                  key={i}
                  header={section.header}
                  score={score}
                  maxScore={10}
                  color={getColorByScore(score)}
                />
              );
            })}
          </Container>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "none" }}
          className="printer-display-static"
        >
          <Grid container className="printer-padding">
            {props.questionnaire.sections.map((section, i) => {
              if (!section.isMeasurable) {
                return <></>;
              }

              let score = calculateScore(section.questions);

              return (
                <Grid align="left" item xs={4}>
                  <ResultBar
                    key={i}
                    header={section.header}
                    score={score}
                    maxScore={10}
                    color={getColorByScore(score)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Container align="center">
            <div
              style={{
                display: "flex",
                float: "right",
                marginBottom: "-15rem"
              }}
            >
              <SpiderwebModal />
            </div>
            <RadarChart
              captions={questionaireCaptions(props.questionnaire.sections)}
              data={questionaireData(props.questionnaire.sections)}
              size={isMobile() ? 300 : 500}
              options={{
                scales: 10,
                zoomDistance: 1.3,
                captionProps: () => ({
                  className: "radar-caption",
                  textAnchor: "middle",
                  fontSize: spiderWebFontSize,
                  fontFamily: "Poppins"
                }),
                wrapCaptionAt: 25,
                captionMargin: 35
              }}
            />
          </Container>
        </Grid>
      </Grid>
      <Grid container>
        <Grid align="left" item md={4} xs={6}>
          <div style={{ marginLeft: "12rem", marginTop: "3.5rem" }}>
            <AnswersModal questionnaire={props.questionnaire.sections} />
          </div>
        </Grid>
        <Grid align="left" item md={4} xs={6}>
          {!isProfessional && !isShared && hasToken && (
            <ConsentModal
              legalAgreements={props.questionnaire.legalAgreements}
              acceptLegalAgreement={props.acceptLegalAgreement}
              onSave={() =>
                props.persistQuestionnaire(
                  props.answeredQuestionnaireId,
                  props.questionnaire,
                  props.questionnaireToken
                )
              }
            />
          )}
        </Grid>
      </Grid>
      {(isProfessional || isShared) && <CategoryRecommendations />}

      <PrintableAnswers questionnaire={props.questionnaire.sections} />
      <ShareQuestionnaireModal
        open={isShareOpen}
        onClose={() => setShareOpen(false)}
        shareQuestionnaire={email =>
          props.shareQuestionnaire(email, props.questionnaireToken)
        }
      />
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    questionnaire: state.questionnaire.questionnaire,
    answeredQuestionnaireId: state.questionnaire.answeredQuestionnaireId,
    questionnaireToken: state.questionnaire.token,
    userRole: state.client.userRole,
    client: state.client.client
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: options => {
      dispatch(setNavigationOptions(options));
    },
    moveToStep: step => {
      dispatch(moveToStep(step));
    },
    acceptLegalAgreement: index => {
      dispatch(acceptLegalAgreement(index));
    },
    shareQuestionnaire: (email, token) => {
      dispatch(shareQuestionnaire(email, token));
    },
    persistQuestionnaire: (
      answeredQuestionnaireId,
      questionnaire,
      questionnaireToken
    ) => {
      dispatch(
        persistQuestionnaire(
          questionnaire.id,
          JSON.stringify(questionnaire),
          answeredQuestionnaireId,
          questionnaireToken
        )
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireResults);
