import { Container } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import * as React from "react"
import { connect } from "react-redux"
import { setNavigationOptions } from "pact.drural.app-skeleton"
import { moveToStep } from "../actions/questionnaireActions"

const NavigationOptions = (props) =>  {
    return {
        isBackEnabled: true,
        isContinueEnabled: props.type === "Referral", 
        onBackClick: props.type === "Referral" ? () => props.moveToStep("recommendationlist") : () => props.moveToStep("professionalentry"),
        onContinueClick: () => props.moveToStep("") 
    }

}

class Placeholder extends React.Component {
    componentDidMount() {
        this.props.setNavigation(NavigationOptions(this.props))
    }

    render() {
        if (this.props.type === "Referral") {
            return(
                <Container align="center"><Alert severity="info">This is just a placeholder that will be replaced with the referral functionality later.</Alert></Container>
            )
        }
    
        return(
            <Container align="center"><Alert severity="info">This is just a placeholder that will be replaced with other interconnected complex service functionality.</Alert></Container>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        setNavigation: (options) => {
            dispatch(setNavigationOptions(options))
        },
        moveToStep: (step) => {
            dispatch(moveToStep(step))
        }
    }
}

export default connect(null, mapDispatchToProps)(Placeholder)