import { Container, LinearProgress, Typography } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { setNavigationOptions } from "pact.drural.app-skeleton";
import {
  enterOtherValue,
  moveToStep,
  nextQuestion,
  persistQuestionnaire,
  previousQuestion,
  selectValue
} from "../../actions/questionnaireActions";
import { validateQuestionnaireSection } from "../../actions/validationActions";
import { sliderConfig, sliderConfigMobile } from "../../utils/config";
import { isMobile } from "pact.drural.utils";
import MultipleChoiceQuestion from "../elements/Questionnaire/MultipleChoiceQuestion";
import SelectionQuestion from "../elements/Questionnaire/SelectionQuestion";
import SliderQuestion from "../elements/Questionnaire/SliderQuestion";
import { RichTextViewer } from "pact.drural.ui";

const NavigationOptions = props => {
  return {
    isBackEnabled: true,
    isContinueEnabled: true,
    onBackClick:
      props.currentSectionIndex === 0
        ? () =>
            props.questionnaire.introPage === null
              ? props.moveToStep("entry")
              : props.moveToStep("questionnaireintro")
        : () => props.previousQuestion(),
    backButtonText: props.currentSectionIndex === 0 ? "Vorig" : "Vorig",
    onContinueClick: props.hasFollowingSection
      ? () =>
          props.validateAndContinue(props.currentSection, props.nextQuestion)
      : () =>
          props.validateAndContinue(props.currentSection, () => {
            props.moveToStep("questionnairelegal");
          }),
    continueButtonText: "Volgende"
  };
};

class Questionnaire extends React.Component {
  componentDidMount() {
    this.props.setNavigation(NavigationOptions(this.props));
  }

  componentDidUpdate() {
    this.props.setNavigation(NavigationOptions(this.props));
  }

  render() {
    if (this.props.questionnaire === null) {
      return <></>;
    }

    return (
      <Container align="center">
        <Container style={{ minHeight: "40rem" }}>
          <LinearProgress
            variant="determinate"
            value={this.props.currentProgress}
            style={{ marginBottom: "3rem", height: 10 }}
          />
          <h1>{this.props.currentSection.header}</h1>
          <Typography variant="subtitle1" style={{ marginBottom: "2rem" }}>
            {this.props.currentSection.subheader}
          </Typography>
          {this.props.currentSection.description !== "" ? (
            <Container align="left" style={{ marginBottom: "2rem" }}>
              <RichTextViewer content={this.props.currentSection.description} />
            </Container>
          ) : (
            <></>
          )}

          {this.props.currentSection.questions.map((question, i) => {
            switch (question.type) {
              case 1:
              case 3:
                return (
                  <SelectionQuestion
                    key={i}
                    header={question.text}
                    validationFailed={
                      !this.props.validationSummary.every(
                        q => q.id !== question.id
                      )
                    }
                    options={question.options}
                    onValueSelect={value =>
                      this.props.selectValue(
                        this.props.currentSectionIndex,
                        i,
                        value
                      )
                    }
                    onOtherValueEnter={value =>
                      this.props.enterOtherValue(
                        this.props.currentSectionIndex,
                        i,
                        value
                      )
                    }
                    othersOptions={{
                      hasOtherOption: question.hasOtherOption,
                      isSelected: question.isOtherOptionSelected,
                      text: question.otherOptionText,
                      input: question.otherOptionInput
                    }}
                  />
                );
              case 2:
                return (
                  <MultipleChoiceQuestion
                    key={i}
                    header={question.text}
                    validationFailed={
                      !this.props.validationSummary.every(
                        q => q.id !== question.id
                      )
                    }
                    options={question.options}
                    onValueSelect={value =>
                      this.props.selectValue(
                        this.props.currentSectionIndex,
                        i,
                        value
                      )
                    }
                    onOtherValueEnter={value =>
                      this.props.enterOtherValue(
                        this.props.currentSectionIndex,
                        i,
                        value
                      )
                    }
                    othersOptions={{
                      hasOtherOption: question.hasOtherOption,
                      isSelected: question.isOtherOptionSelected,
                      text: question.otherOptionText,
                      input: question.otherOptionInput
                    }}
                  />
                );
              default:
                return (
                  <SliderQuestion
                    key={i}
                    header={question.text}
                    config={isMobile() ? sliderConfigMobile : sliderConfig}
                    selectedValue={question.selectedValue}
                    validationFailed={
                      !this.props.validationSummary.every(
                        q => q.id !== question.id
                      )
                    }
                    onValueSelect={value =>
                      this.props.selectValue(
                        this.props.currentSectionIndex,
                        i,
                        value
                      )
                    }
                  />
                );
            }
          })}
        </Container>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentSectionIndex: state.questionnaire.currentSectionIndex,
    currentSection:
      state.questionnaire.questionnaire.sections[
        state.questionnaire.currentSectionIndex
      ],
    hasFollowingSection:
      state.questionnaire.questionnaire.sections[
        state.questionnaire.currentSectionIndex + 1
      ] !== undefined,
    currentProgress:
      ((state.questionnaire.currentSectionIndex + 1) /
        (state.questionnaire.questionnaire.sections.length + 1)) *
      100,
    validationSummary: state.validation.questionnaire,
    questionnaire: state.questionnaire.questionnaire,
    answeredQuestionnaireId: state.questionnaire.answeredQuestionnaireId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    validateAndContinue: (section, onSuccess) => {
      dispatch(validateQuestionnaireSection(section, onSuccess));
    },
    nextQuestion: () => {
      dispatch(nextQuestion());
      window.scrollTo(0, 0);
    },
    previousQuestion: () => {
      dispatch(previousQuestion());
      window.scrollTo(0, 0);
    },
    selectValue: (sectionIndex, questionIndex, value) => {
      dispatch(selectValue(sectionIndex, questionIndex, value));
    },
    enterOtherValue: (sectionIndex, questionIndex, value) => {
      dispatch(enterOtherValue(sectionIndex, questionIndex, value));
    },
    setNavigation: options => {
      dispatch(setNavigationOptions(options));
    },
    moveToStep: step => {
      dispatch(moveToStep(step));
    },
    persistQuestionnaire: (answeredQuestionnaireId, questionnaire) => {
      dispatch(
        persistQuestionnaire(
          questionnaire.id,
          JSON.stringify(questionnaire),
          answeredQuestionnaireId
        )
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
