import * as React from "react";
import { connect } from "react-redux";

import {
  AppSkeleton,
  AuthenticatedAppSkeleton
} from "pact.drural.app-skeleton";

import UserRoleHandler from "./UserRoleHandler";
import Entry from "./components/questionnaire/Entry";
import QuestionnaireChoice from "./components/professional/QuestionnaireChoice";
import QuestionnaireIntro from "./components/questionnaire/QuestionnaireIntro";
import Questionnaire from "./components/questionnaire/Questionnaire";
import QuestionaireResults from "./components/questionnaire/QuestionaireResults";
import RecommendationList from "./components/professional/RecommendationList";
import CustomerList from "./components/professional/CustomerList";
import Recommendations from "./components/patient/Recommendations";
import Placeholder from "./components/Placeholder";

import { loadQuestionnaire } from "./actions/questionnaireActions";

import "./app.css";

class App extends React.Component {
  componentDidMount() {
    if (
      this.props.questionnaire.questionnaireId === undefined ||
      this.props.questionnaire.questionnaire === null
    ) {
      this.props.loadQuestionnaire(this.props.questionnaireId);
    }
  }

  componentDidUpdate() {
    if (
      this.props.questionnaire.currentSectionIndex !== 0 &&
      this.props.questionnaire.currentStep === ""
    ) {
      this.props.loadQuestionnaire(this.props.questionnaire.questionnaireId);
    }
  }

  render() {
    switch (this.props.questionnaire.currentStep) {
      case "entry":
        return (
          <AppSkeleton>
            <Entry />
          </AppSkeleton>
        );
      case "questionnaireintro":
        return (
          <AppSkeleton>
            <QuestionnaireIntro />
          </AppSkeleton>
        );
      case "questionnaire":
        return (
          <AppSkeleton>
            <Questionnaire />
          </AppSkeleton>
        );
      case "questionnaireresult":
        return (
          <AppSkeleton>
            <QuestionaireResults />
          </AppSkeleton>
        );
      case "recommendations":
        return (
          <AppSkeleton>
            <Recommendations />
          </AppSkeleton>
        );
      case "customer-selection":
        return (
          <AuthenticatedAppSkeleton>
            <CustomerList />
          </AuthenticatedAppSkeleton>
        );
      case "professionalentry":
        return (
          <AuthenticatedAppSkeleton>
            <QuestionnaireChoice />
          </AuthenticatedAppSkeleton>
        );
      case "recommendationlist":
        return (
          <AuthenticatedAppSkeleton>
            <RecommendationList />
          </AuthenticatedAppSkeleton>
        );
      case "referclient":
        return (
          <AuthenticatedAppSkeleton>
            <Placeholder type="Referral" />
          </AuthenticatedAppSkeleton>
        );
      default:
        return (
          <AppSkeleton>
            <UserRoleHandler />
          </AppSkeleton>
        );
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    questionnaire: state.questionnaire,
    questionnaireId: state.questionnaire.questionnaireId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadQuestionnaire: questionnaireId => {
      dispatch(loadQuestionnaire(questionnaireId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
