import { SET_CLIENT, SET_USER_ROLE } from "../actions/clientActions";

const defaultState = {
    userRole: "",
    client: null
}

const clientReducer = (state = defaultState, action)=> {
    switch (action.type) {
        case SET_USER_ROLE:
            return {
                ...state,
                userRole: action.role
            }
        case SET_CLIENT:
            return {
                ...state,
                client: action.client
            }
        default:
            return state;
    }
}

export default clientReducer;