import {
  SportsBasketballOutlined,
  RestaurantOutlined,
  HouseOutlined,
  ChildCareOutlined,
  SentimentSatisfiedAltOutlined,
  AutorenewOutlined,
  Euro,
  LocalLibraryOutlined,
  FavoriteBorderOutlined,
  LocalTaxiOutlined,
  QuestionAnswer
} from "@material-ui/icons";
import React from "react";

export const CategoryIcon = ({ categoryName }) => {
  switch (categoryName) {
    case "Bewegen":
      return <SportsBasketballOutlined className="categoryIcon" />;
    case "Mentaal welbevinden":
      return (
        <svg
          className="MuiSvgIcon-root categoryIcon"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="PsychologyOutlinedIcon"
        >
          <path d="m15.82 7.22-1 .4c-.21-.16-.43-.29-.67-.39L14 6.17c-.02-.1-.1-.17-.2-.17h-1.6c-.1 0-.18.07-.19.17l-.15 1.06c-.24.1-.47.23-.67.39l-1-.4c-.09-.03-.2 0-.24.09l-.8 1.38c-.05.09-.03.2.05.26l.85.66c-.03.12-.05.26-.05.39s.01.26.03.39l-.84.66c-.08.06-.1.17-.05.25l.8 1.39c.05.09.15.12.25.09l.99-.4c.21.16.43.29.68.39l.14 1.06c.02.1.1.17.2.17h1.6c.1 0 .18-.07.2-.17l.15-1.06c.24-.1.47-.23.67-.39l.99.4c.09.04.2 0 .24-.09l.8-1.39c.05-.09.03-.19-.05-.25l-.83-.66c.02-.13.03-.26.03-.39 0-.14-.01-.27-.03-.39l.85-.66c.08-.06.1-.17.05-.26l-.8-1.38c-.05-.09-.16-.12-.25-.09zM13 11.43c-.79 0-1.43-.64-1.43-1.43s.64-1.43 1.43-1.43 1.43.64 1.43 1.43-.64 1.43-1.43 1.43z"></path>
          <path d="M19.94 9.06c-.43-3.27-3.23-5.86-6.53-6.05C13.27 3 13.14 3 13 3 9.47 3 6.57 5.61 6.08 9l-1.93 3.48c-.41.66.07 1.52.85 1.52h1v2c0 1.1.9 2 2 2h1v3h7v-4.68c2.62-1.25 4.35-4.08 3.94-7.26zm-5.05 5.57-.89.42V19h-3v-3H8v-4H6.7l1.33-2.33C8.21 7.06 10.35 5 13 5c2.76 0 5 2.24 5 5 0 2.09-1.29 3.88-3.11 4.63z"></path>
        </svg>
      );
    case "Voeding":
      return <RestaurantOutlined className="categoryIcon" />;
    case "Wonen / huishouden":
      return <HouseOutlined className="categoryIcon" />;
    case "Sociale omgeving / Contacten":
      return (
        <svg
          className="MuiSvgIcon-root categoryIcon"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="SocialDistanceOutlinedIcon"
        >
          <path d="M4 5c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm4.78 3.58C7.93 8.21 6.99 8 6 8s-1.93.21-2.78.58C2.48 8.9 2 9.62 2 10.43V11h8v-.57c0-.81-.48-1.53-1.22-1.85zM18 7c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm2.78 1.58C19.93 8.21 18.99 8 18 8c-.99 0-1.93.21-2.78.58-.74.32-1.22 1.04-1.22 1.85V11h8v-.57c0-.81-.48-1.53-1.22-1.85zm-2.77 4.43-1.41 1.41L18.17 16H5.83l1.58-1.59L6 13l-4 4 3.99 3.99 1.41-1.41L5.83 18h12.34l-1.58 1.58L18 20.99 22 17l-3.99-3.99z"></path>
        </svg>
      );
    case "Opvoeden":
      return <ChildCareOutlined className="categoryIcon" />;
    case "Slapen / Ontspanning":
      return (
        <svg
          className="MuiSvgIcon-root categoryIcon"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="SelfImprovementOutlinedIcon"
        >
          <circle cx="12" cy="6" r="2"></circle>
          <path d="M21 16v-2c-2.24 0-4.16-.96-5.6-2.68l-1.34-1.6c-.38-.46-.94-.72-1.53-.72h-1.05c-.59 0-1.15.26-1.53.72l-1.34 1.6C7.16 13.04 5.24 14 3 14v2c2.77 0 5.19-1.17 7-3.25V15l-3.88 1.55c-.67.27-1.12.93-1.12 1.66C5 19.2 5.8 20 6.79 20H9v-.5c0-1.38 1.12-2.5 2.5-2.5h3c.28 0 .5.22.5.5s-.22.5-.5.5h-3c-.83 0-1.5.67-1.5 1.5v.5h7.21c.99 0 1.79-.8 1.79-1.79 0-.73-.45-1.39-1.12-1.66L14 15v-2.25c1.81 2.08 4.23 3.25 7 3.25z"></path>
        </svg>
      );
    case "Persoonlijke verzorging":
      return <SentimentSatisfiedAltOutlined className="categoryIcon" />;
    case "Gewoontes":
      return <AutorenewOutlined className="categoryIcon" />;
    case "Financieel / werk":
      return <Euro className="categoryIcon" />;
    case "Vervoer":
      return <LocalTaxiOutlined className="categoryIcon" />;
    case "Cultuur":
      return <LocalLibraryOutlined className="categoryIcon" />;
    case "Fysiek welbevinden":
      return <FavoriteBorderOutlined className="categoryIcon" />;
    default:
      return <QuestionAnswer className="categoryIcon" />;
  }
};
