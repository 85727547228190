import { getToken } from "pact.drural.app-skeleton";

export const START_RECOMMENDATION_ENGINE = "START_RECOMMENDATION_ENGINE";
export const SET_RECOMMENDATION_IDS = "SET_RECOMMENDATION_IDS";
export const SET_RECOMMENDATION_CATEGORIES = "SET_RECOMMENDATION_CATEGORIES";
export const SET_RECOMMENDATION_SUCCESS = "SET_RECOMMENDATION_SUCCESS";
export const START_RECOMMENDATION_REQUEST = "START_RECOMMENDATION_REQUEST";
export const START_LOAD_CATEGORIES = "START_LOAD_CATEGORIES";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const START_SAVE_QUESTIONNAIRE_ADVICE =
  "START_SAVE_QUESTIONNAIRE_ADVICE";
export const END_SAVE_QUESTIONNAIRE_ADVICE = "END_SAVE_QUESTIONNAIRE_ADVICE";
export const START_LOAD_QUESTIONNAIRE_ADVICES =
  "START_LOAD_QUESTIONNAIRE_ADVICES";
export const SET_QUESTIONNAIRE_ADVICES = "SET_QUESTIONNAIRE_ADVICES";

export const startLoadCategories = () => ({
  type: START_LOAD_CATEGORIES
});

export const setCategories = categories => ({
  type: SET_CATEGORIES,
  categories
});

const startSaveQuestionnaireAdvice = () => ({
  type: START_SAVE_QUESTIONNAIRE_ADVICE
});

const endSaveQuestionnaireAdvice = () => ({
  type: END_SAVE_QUESTIONNAIRE_ADVICE
});

export const startLoadQuestionnaireAdvices = () => ({
  type: START_LOAD_QUESTIONNAIRE_ADVICES
});

export const setQuestionnaireAdvices = advices => ({
  type: SET_QUESTIONNAIRE_ADVICES,
  advices
});

export const startRecommendationRequest = () => ({
  type: START_RECOMMENDATION_REQUEST
});

export const setRecommendationSuccess = () => ({
  type: SET_RECOMMENDATION_SUCCESS
});

export const setRecommendationEngineLoading = () => ({
  type: START_RECOMMENDATION_ENGINE
});

export const setRecommendationCategories = categories => ({
  type: SET_RECOMMENDATION_CATEGORIES,
  categories
});

export const loadAvailableCategories = () => (dispatch: any) => {
  dispatch(startLoadCategories());

  return fetch(process.env.REACT_APP_PACT_GATEWAY_URI + "v1/category/list", {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      dispatch(setCategories(json));
    });
};

export const saveQuestionnaireAdvice =
  (token, categoryIds, name, message) => (dispatch: any) => {
    dispatch(startSaveQuestionnaireAdvice());

    return fetch(
      process.env.REACT_APP_PACT_GATEWAY_URI + "v1/recommendation/advice/save",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          token,
          categoryIds,
          name,
          message
        })
      }
    ).then(() => {
      dispatch(endSaveQuestionnaireAdvice());
      dispatch(loadQuestionnaireAdvices(token));
    });
  };

export const loadQuestionnaireAdvices = token => (dispatch: any) => {
  dispatch(startLoadQuestionnaireAdvices());

  return fetch(
    process.env.REACT_APP_PACT_GATEWAY_URI +
      "v1/recommendation/advice/" +
      token,
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      }
    }
  )
    .then(response => {
      return response.json();
    })
    .then(json => {
      dispatch(setQuestionnaireAdvices(json));
    });
};

export const startInternalRecommendationEngine = payload => (dispatch: any) => {
  dispatch(setRecommendationEngineLoading());

  return fetch(
    process.env.REACT_APP_PACT_GATEWAY_URI + "v1/recommendation/internal",
    {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken()
      },
      body: JSON.stringify({
        payload: JSON.stringify(payload)
      })
    }
  )
    .then(response => {
      return response.json();
    })
    .then(json => {
      dispatch(setRecommendationSuccess());
      dispatch(setRecommendationCategories(json));
    });
};

export const setRecommendationIds = (flowId: string, executionId: string) => ({
  type: SET_RECOMMENDATION_IDS,
  executionId,
  flowId
});

export const startRecommendationEngine = payload => (dispatch: any) => {
  dispatch(setRecommendationEngineLoading());

  return fetch(process.env.REACT_APP_PACT_GATEWAY_URI + "v1/recommendation", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken()
    },
    body: JSON.stringify({
      payload: JSON.stringify(payload)
    })
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      dispatch(setRecommendationIds(json.flowId, json.executionId));
    });
};

export const checkAiFlow =
  (flowId: string, executionId: string) => (dispatch: any) => {
    dispatch(startRecommendationRequest());

    return getToken().then(token => {
      return fetch(
        process.env.REACT_APP_PACT_GATEWAY_URI +
          "v1/recommendation/flow/" +
          flowId +
          "/" +
          executionId +
          "/check",
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          }
        }
      )
        .then(response => {
          return response.json();
        })
        .then(json => {
          console.log(json);
          if (json.state === "successful") {
            return dispatch(setRecommendationSuccess());
          } else {
            dispatch(startRecommendationRequest());
            return Promise.resolve();
          }
        });
    });
  };

export const queryRecommendations = (flowId: string) => (dispatch: any) => {
  dispatch(startRecommendationRequest());

  return getToken().then(token => {
    return fetch(
      process.env.REACT_APP_PACT_GATEWAY_URI +
        "v1/recommendation/flow/" +
        flowId,
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        }
      }
    )
      .then(response => {
        return response.json();
      })
      .then(json => {
        dispatch(setRecommendationCategories(json));
      });
  });
};
