import { useQuery } from "@apollo/client";
import { UserRole } from "../enums/UserRole";
import { GetUserSellerInfoQuery } from "../graphql/GetUserSellerInfoQuery";
import { useEffect, useState } from "react";

export const useUserRole = () => {
  const [userRole, setUserRole] = useState<UserRole | null>(null);

  const query = useQuery(GetUserSellerInfoQuery);
  useEffect(() => {
    if (!userRole && !query.loading && query.data) {
      const isSeller = query.data.me.isSeller;
      setUserRole(isSeller ? UserRole.Professionel : UserRole.Client);
    }
  }, [userRole, query.loading, query.data]);

  return { isLoading: query.loading, userRole };
};
