import {
  Box,
  Grid,
  Typography,
  Container,
  Chip,
  Divider
} from "@material-ui/core";
import { RichTextViewer } from "pact.drural.ui";
import React from "react";

const RecommendationMessageContainer = props => {
  if (!props.messages) {
    return <></>;
  }

  if (!props.availableCategories) {
    return <></>;
  }

  return (
    <Container align="left">
      {props.messages.map(message => (
        <Box
          key={message.id}
          style={{
            marginLeft: "1rem",
            marginBottom: "1.5rem",
            paddingBottom: "1rem",
            paddingLeft: ".5rem",
            backgroundColor: "#E0DEE3",
            width: "97.5%",
            borderRadius: 4
          }}
        >
          <Grid container>
            <Grid item md={12} xs={6} align="left">
              <Typography
                variant="subtitle2"
                style={{
                  paddingTop: "2rem",
                  paddingLeft: "2rem",
                  marginBottom: "-2rem",
                  fontWeight: 600
                }}
              >
                {message.name}
              </Typography>
            </Grid>
            <Grid item md={12} xs={6} align="right">
              <Typography
                variant="subtitle2"
                style={{
                  paddingTop: "0rem",
                  marginBottom: "-2rem",
                  marginRight: "2rem"
                }}
              >
                {new Date(message.timestamp).toLocaleString("nl-NL")}
              </Typography>
            </Grid>
          </Grid>
          <Divider
            style={{
              background: "#aaaaae",
              marginTop: "3rem",
              marginLeft: ".5rem",
              marginRight: "1rem"
            }}
          />
          <Grid
            container
            style={{ width: "100%", marginTop: "1rem", paddingLeft: "1rem" }}
            spacing={1}
          >
            {message.categoryIds.map(categoryId => {
              const category = props.availableCategories.find(
                c => c.id === categoryId
              );

              return (
                <Grid item>
                  <Box
                    key={categoryId}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5
                    }}
                  >
                    <Chip
                      label={category?.name}
                      style={{ background: "#83be00" }}
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>

          <RichTextViewer content={message.message} />
        </Box>
      ))}
    </Container>
  );
};

export default RecommendationMessageContainer;
