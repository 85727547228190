import { TOGGLE_SEND_MODAL } from "../actions/modalActions";

const defaultState = {
    isSendModalOpen: false
}

const modalReducer = (state = defaultState, action)=> {
    switch (action.type) {
        case TOGGLE_SEND_MODAL:
            return {
                ...state,
                isSendModalOpen: !state.isSendModalOpen
            }
        default:
            return state;
    }
}

export default modalReducer;