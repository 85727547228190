import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";

import { loadQuestionnaireList } from "../actions/questionnaireActions";
import { FilterableList } from "pact.drural.ui";
import {
  setNavigationOptions,
  translate,
  useTranslations
} from "pact.drural.app-skeleton";
import { Alert } from "@material-ui/lab";

const QuestionnaireList = props => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isMounted, setMounted] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [lastSearch, setLastSearch] = useState("");

  const t = useTranslations();

  useEffect(() => {
    if (!isMounted) {
      props.setNavigation({
        isBackVisible: false,
        isContinueVisible: false
      });
      props.loadQuestionnaireList({ first: rowsPerPage });

      setMounted(true);
    }

    if (searchTerm !== lastSearch) {
      const delaySearchTimeout = setTimeout(() => {
        props.loadQuestionnaireList({ first: rowsPerPage, search: searchTerm });
        setLastSearch(searchTerm);
      }, 300);

      return () => clearTimeout(delaySearchTimeout);
    }
  }, [props, isMounted, rowsPerPage, searchTerm, lastSearch, setLastSearch]);

  if (props.error) {
    return (
      <div style={{ minHeight: "47rem", marginTop: "20rem" }}>
        <Alert severity="error" title="Error" style={{ fontSize: "2rem" }}>
          An error occured loading the questionnaire list. Please try again
          later.
        </Alert>
      </div>
    );
  }

  return (
    <div style={{ minHeight: "47rem" }}>
      <FilterableList
        state={props.state}
        label={translate("SEARCH", t)}
        getTranslation={key => translate(key, t)}
        columnCount={5}
        onSearch={(rowsPerPage, searchQuery) => {
          setRowsPerPage(rowsPerPage);
          setSearchTerm(searchQuery);
        }}
        onPageChange={(page, currentPage, rowsPerPage, searchQuery) => {
          setRowsPerPage(rowsPerPage);

          if (page > currentPage) {
            props.loadQuestionnaireList({
              first: rowsPerPage,
              after: props.state.pageInfo.endCursor,
              search: searchQuery
            });
          } else {
            props.loadQuestionnaireList({
              last: rowsPerPage,
              before: props.state.pageInfo.startCursor,
              search: searchQuery
            });
          }
        }}
        onRowsPerPageChange={(rowsPerPage, searchQuery) => {
          setRowsPerPage(rowsPerPage);

          props.loadQuestionnaireList({
            first: rowsPerPage,
            search: searchQuery
          });
        }}
        tableHead={
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "33.3%" }}>
                {translate("SERVICES_PROVIDER_TABLE_HEADER", t)}
              </TableCell>
              <TableCell style={{ width: "33.3%" }}>
                {translate("QUESTIONNAIRE_NAME", t)}
              </TableCell>
              <TableCell style={{ width: "33.3%" }}>
                {translate("QUESTIONNAIRE_CREATED_ON", t)}
              </TableCell>
            </TableRow>
          </TableHead>
        }
        tableBody={
          <TableBody>
            {props.state.items.map((questionnaire, i) => {
              return (
                <TableRow
                  key={i}
                  onClick={() =>
                    window.open(
                      "/questionnaire?qid=" + questionnaire.id,
                      "_blank"
                    )
                  }
                >
                  <TableCell>
                    <Typography>{questionnaire.serviceProviderName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{questionnaire.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{questionnaire.creationDate}</Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        }
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    state: state.questionnaire.list,
    error: state.error.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: options => {
      dispatch(setNavigationOptions(options));
    },
    loadQuestionnaireList: () => {
      dispatch(loadQuestionnaireList());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireList);
