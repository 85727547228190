import React, { useState } from "react";

import { isMobile } from "pact.drural.utils";
import {
  Box,
  Button,
  Modal,
  Typography,
  Grid,
  Checkbox
} from "@material-ui/core";
import { defaultTextColor } from "../../../utils/config";

const style = () => {
  return {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile() ? "100%" : "90rem",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 8,
    height: "71rem"
  };
};

export const ConsentModal = ({
  legalAgreements,
  acceptLegalAgreement,
  onSave
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="contained"
        color="primary"
        style={{ marginTop: "3.5rem", width: "24rem" }}
        className="hide-on-print"
      >
        Toestemming beheren
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style()}>
          <Typography
            id="modal-modal-title"
            variant="h3"
            style={{ marginBottom: "3rem" }}
          >
            Toestemming beheren
          </Typography>
          <Typography style={{ marginBottom: "4rem" }}>
            As per GDPR you, as a customer, have the right to remove permission
            for our use of your personal data. You can also allow your data to
            be used in the processes that help us improve the qustionnaire.
            Please note that removing consent to the privacy declaration will
            force us to delete your filled questionnaire. Therefore we can not
            offer our service to you anymore afterwards.
          </Typography>
          {legalAgreements.map((agreement, i) => {
            return (
              <Grid key={i} container style={{ marginBottom: "3rem" }}>
                <Grid item md={1} xs={1}>
                  <Checkbox
                    checked={agreement.isChecked}
                    onClick={() => acceptLegalAgreement(i)}
                  />
                </Grid>
                <Grid item md={11}>
                  <div
                    style={{
                      float: "left",
                      textAlign: "left",
                      paddingTop: ".75rem"
                    }}
                  >
                    <Typography
                      style={{
                        color: defaultTextColor,
                        fontWeight: 400,
                        marginBottom: "2rem"
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: agreement.text }}
                      />
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            );
          })}
          <Grid container>
            <Grid item md={6} xs={6}>
              <Button
                style={{
                  marginTop: "3rem",
                  width: "22rem",
                  float: "left",
                  marginLeft: "3rem"
                }}
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                Sluiten
              </Button>
            </Grid>
            <Grid item md={6} xs={6}>
              <Button
                style={{
                  marginTop: "3rem",
                  width: "22rem",
                  backgroundColor: "#882345",
                  color: "white",
                  float: "right",
                  marginRight: "3rem"
                }}
                variant="contained"
                onClick={() => {
                  onSave();
                  handleClose();
                }}
              >
                Opslaan
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
