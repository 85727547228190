import React, { useState } from "react";

import { isMobile } from "pact.drural.utils";
import {
  Box,
  Button,
  Modal,
  Typography,
  Grid,
  IconButton
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";

const style = () => {
  return {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile() ? "100%" : "90rem",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 8,
    height: "27rem"
  };
};

export const AiInfoModal = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        variant="contained"
        color="secondary"
        className="hide-on-print"
      >
        <InfoOutlined
          style={{
            paddingBottom: ".75rem",
            float: "right",
            width: "24px",
            height: "24px"
          }}
        />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style()}>
          <Typography style={{ marginBottom: "4rem" }}>
            De aanbevelingen die u hierboven ziet, worden gegenereerd door een
            systeem van kunstmatige intelligentie (AI). Deze AI analyseert uw
            antwoorden op de Positieve Gezondheidsvragenlijst om suggesties te
            doen voor categorieën van diensten. We streven naar transparantie en
            willen dat u zich er volledig van bewust bent dat deze aanbevelingen
            door een AI zijn gemaakt. Als u vragen, opmerkingen heeft of verdere
            hulp nodig heeft, kunt u contact met ons opnemen via
            info@samenkwiek.nu
          </Typography>
          <Grid container>
            <Grid item md={6} xs={6}>
              <Button
                style={{
                  width: "22rem",
                  float: "left",
                  marginLeft: "3rem"
                }}
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                Sluiten
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
