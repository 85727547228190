import { UserRole } from "../enums/UserRole";

export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_CLIENT = "SET_CLIENT";

export const setUserRole = (role: UserRole) => ({
  type: SET_USER_ROLE,
  role: role
});

export const setClient = client => ({
  type: SET_CLIENT,
  client
});
