import { SearchRequest, generateQuery } from "pact.drural.ui";
import { Customer } from "../types/Customer";
import {
  getToken,
  toggleBackdropSpinner,
  generateUrl,
  routes
} from "pact.drural.app-skeleton";
import { raiseError } from "./errorActions";

export const START_LOAD_CUSTOMERS = "START_LOAD_CUSTOMERS";

export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const SET_CUSTOMER = "SET_CUSTOMER";

export const SELECT_CUSTOMER = "SELECT_CUSTOMER";
export const UNSELECT_CUSTOMER = "UNSELECT_CUSTOMER";

const startLoadCustomers = () => ({
  type: START_LOAD_CUSTOMERS
});

const setCustomers = (customers: any) => ({
  type: SET_CUSTOMERS,
  result: customers
});

export const loadCustomers = (request: SearchRequest) => (dispatch: any) => {
  dispatch(startLoadCustomers());

  return getToken()
    .then(token => {
      return fetch(
        generateUrl(routes.customer.v1.list) + generateQuery(request),
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          }
        }
      );
    })
    .then(response => {
      if (response.status !== 200) {
        dispatch(
          raiseError([{ message: "CUSTOMER_NOT_LOADABLE", severity: "error" }])
        );
      } else {
        return response.json();
      }
    })
    .then(json => {
      dispatch(setCustomers(json));
    })
    .catch(() => {
      dispatch(
        raiseError([{ message: "CUSTOMER_NOT_LOADABLE", severity: "error" }])
      );
    });
};

const setCustomer = (customer: Customer) => ({
  type: SET_CUSTOMER,
  customer
});

export const loadCustomer =
  (customerId: string, withSpinner: boolean = true) =>
  (dispatch: any) => {
    if (withSpinner) {
      dispatch(toggleBackdropSpinner());
    }

    return getToken()
      .then(token => {
        return fetch(generateUrl(routes.customer.v1.customer, { customerId }), {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          }
        });
      })
      .then(response => response.json())
      .then(json => {
        dispatch(setCustomer(json.customer));
      })
      .finally(() => {
        if (withSpinner) {
          dispatch(toggleBackdropSpinner());
        }
      });
  };

export const selectCustomer = (customerId: string) => ({
  type: SELECT_CUSTOMER,
  customerId: customerId
});

export const unselectCustomer = () => ({
  type: UNSELECT_CUSTOMER
});
