import { CLEAR_LEGAL_VALIDATION_SUMMARY, CLEAR_QUESTIONNAIRE_VALIDATION_SUMMARY, SET_LEGAL_VALIDATION_SUMMARY, SET_QUESTIONNAIRE_VALIDATION_SUMMARY, VALIDATE_CUSTOMER_SUMMARY } from "../actions/validationActions";

const defaultState = {
    questionnaire: [],
    legal: [],
    customer: null
}

const validationReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_LEGAL_VALIDATION_SUMMARY:
            return {
                ...state,
                legal: action.summary
            }
        case CLEAR_LEGAL_VALIDATION_SUMMARY:
            return {
                ...state,
                legal: []
            }
        case SET_QUESTIONNAIRE_VALIDATION_SUMMARY:
            return Object.assign({}, state, {
                questionnaire: action.summary
            })
        case CLEAR_QUESTIONNAIRE_VALIDATION_SUMMARY:
            return Object.assign({}, state, {
                questionnaire: []
            })
        case VALIDATE_CUSTOMER_SUMMARY:
            return Object.assign({}, state, {
                customer: {
                    isValid: action.isValid
                }
            })
        default:
            return state;
    }
}

export default validationReducer;