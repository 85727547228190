import {
  Container,
  Typography,
  Grid,
  CircularProgress,
  Card,
  CardContent,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Chip,
  TextField
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  checkAiFlow,
  queryRecommendations,
  startRecommendationEngine,
  startInternalRecommendationEngine,
  loadAvailableCategories,
  loadQuestionnaireAdvices,
  saveQuestionnaireAdvice
} from "../../actions/recommendationActions";
import { translate, useTranslations } from "pact.drural.app-skeleton";
import { useInterval } from "../../hooks/useInterval";
import { shouldUseExternalRecommendationEngine } from "../../utils/config";
import { CategoryIcon } from "../elements/Questionnaire/CategoryIcon";
import { AiInfoModal } from "../modals/AiInfoModal";
import { RichTextEditor } from "pact.drural.ui";
import { getUrlParameter } from "pact.drural.utils";
import RecommendationMessageContainer from "../elements/RecommendationMessageContainer";

const CategoryRecommendations = ({
  state,
  questionnaire,
  startInternalRecommendationEngine,
  startRecommendationEngine,
  checkAiFlow,
  getRecommendations,
  loadAvailableCategories,
  loadQuestionnaireAdvices,
  saveQuestionnaireAdvice
}) => {
  const [recommendedCategories, setRecommendedCategories] = useState([]);
  const handleRecommendationChange = event => {
    setRecommendedCategories(event.target.value);
  };
  const [recommenderName, setRecommenderName] = useState("");
  const [recommendationDescription, setRecommendationDescription] =
    useState("");
  const [isRecommendationPrefilled, setRecommendationWasPrefilled] =
    useState(false);

  const t = useTranslations();

  const token = getUrlParameter("token");
  const hasToken = token !== null;

  useEffect(() => {
    if (
      !state.isLoadingAvailableCategories &&
      state.availableCategories === null
    ) {
      loadAvailableCategories();
    }
  }, [state, loadAvailableCategories]);

  useEffect(() => {
    if (!state.isLoadingAdvices && state.advices === null && hasToken) {
      loadQuestionnaireAdvices(token);
    }
  }, [state, loadQuestionnaireAdvices, token, hasToken]);

  useEffect(() => {
    if (state.advices !== null && hasToken && !isRecommendationPrefilled) {
      const advice = state.advices.find(a => a.isOwn);

      if (advice) {
        setRecommendedCategories(advice.categoryIds);
        setRecommenderName(advice.name);
        setRecommendationDescription(advice.message);
      }

      setRecommendationWasPrefilled(true);
    }
  }, [
    state,
    token,
    hasToken,
    isRecommendationPrefilled,
    setRecommendationWasPrefilled
  ]);

  useEffect(() => {
    if (
      !state.isLoading &&
      state.categories.length === 0 &&
      !state.isEngineRunning
    ) {
      if (shouldUseExternalRecommendationEngine()) {
        startRecommendationEngine(questionnaire);
      } else {
        startInternalRecommendationEngine(questionnaire);
      }
    }
  }, [
    state,
    questionnaire,
    startInternalRecommendationEngine,
    startRecommendationEngine
  ]);

  useInterval(() => {
    if (!shouldUseExternalRecommendationEngine()) {
      return;
    }

    if (state.flowId === "" || state.executionId === "") {
      return;
    }

    if (state.isLoading && state.isEngineRunning && !state.isRequestInflight) {
      checkAiFlow(state.flowId, state.executionId);
    }

    if (state.isLoading && !state.isEngineRunning && !state.isRequestInflight) {
      getRecommendations(state.flowId);
    }
  }, 5000);

  if (state.isLoading) {
    return (
      <Container style={{ marginTop: "4rem" }}>
        <Typography variant="h3">
          {translate("QUESTIONNAIRE_RESULTS_RECOMMENDATIONS", t)}
        </Typography>
        <CircularProgress style={{ marginTop: "4rem" }} />
      </Container>
    );
  }

  return (
    <>
      <Container style={{ marginTop: "4rem", marginBottom: "12rem" }}>
        <Typography variant="h3">
          {translate("QUESTIONNAIRE_RESULTS_RECOMMENDATIONS", t)}
          <AiInfoModal />
        </Typography>

        <Grid
          container
          justifyContent="center"
          style={{
            marginTop: "4rem"
          }}
        >
          {state.categories.map((category, i) => {
            if (i > 3) {
              return <></>;
            }

            return (
              <Grid
                key={i}
                item
                md={2}
                style={{
                  cursor: "pointer"
                }}
                onClick={() =>
                  window.open(category.uri ?? category.url, "_blank").focus()
                }
              >
                <div
                  style={{
                    borderRadius: "100%",
                    height: "144px",
                    margin: ".5rem",
                    width: "144px"
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                      borderRadius: "100px",
                      backgroundColor: "#ecfaca", //rgb(244, 250, 229)",
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      width: "100%"
                    }}
                  >
                    <div style={{ paddingTop: ".5rem" }}>
                      <CategoryIcon categoryName={category.name} />
                    </div>
                  </div>
                  <Typography
                    style={{
                      marginTop: "22px",
                      textAlign: "center",
                      fontSize: "1.5rem",
                      fontWeight: 500
                    }}
                  >
                    {category.name}
                  </Typography>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Container>
      {hasToken && (
        <Container style={{ marginTop: "4rem" }}>
          <Typography variant="h3" style={{ marginBottom: "2rem" }}>
            Advies
          </Typography>
        </Container>
      )}
      {hasToken && state.advices !== null && (
        <RecommendationMessageContainer
          messages={state.advices}
          availableCategories={state.availableCategories}
        />
      )}
      {hasToken && (
        <Container
          style={{
            marginTop: "4rem",
            marginBottom: "10rem",
            paddingLeft: "3.5rem",
            paddingRight: "4.25rem"
          }}
        >
          <Card>
            <CardContent>
              <Grid container>
                <Grid md={3} xs={12} align="left">
                  {state.availableCategories && (
                    <FormControl variant="filled" style={{ width: "30rem" }}>
                      <InputLabel>Categorie</InputLabel>
                      <Select
                        value={recommendedCategories}
                        label="Category"
                        onChange={handleRecommendationChange}
                        multiple
                      >
                        {state.availableCategories.map((category, i) => {
                          return (
                            <MenuItem value={category.id}>
                              {category.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}
                  <Grid container spacing={1} style={{ marginTop: "1rem" }}>
                    {state.availableCategories && (
                      <>
                        {recommendedCategories.map(categoryId => {
                          const category = state.availableCategories.find(
                            c => c.id === categoryId
                          );

                          return (
                            <Grid item>
                              <Box
                                key={categoryId}
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5
                                }}
                              >
                                <Chip label={category?.name} />
                              </Box>
                            </Grid>
                          );
                        })}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid md={9} xs={12} align="right">
                  <Container style={{ marginLeft: "2rem" }}>
                    <Grid container fullWidth>
                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          align="left"
                          label="Naam (optioneel)"
                          style={{ marginLeft: "1rem", paddingRight: "2rem" }}
                          value={recommenderName}
                          onChange={event =>
                            setRecommenderName(event.target.value)
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: "1rem" }}>
                      <Container
                        align="left"
                        style={{
                          paddingLeft: "0",
                          paddingRight: "0",
                          marginBottom: "1rem"
                        }}
                      >
                        <RichTextEditor
                          content={recommendationDescription}
                          onSave={setRecommendationDescription}
                          minHeight="25rem"
                          placeholder={translate(
                            "RICHTEXT_EDITOR_START_TYPING",
                            t
                          )}
                        />
                      </Container>
                    </Grid>
                    <Grid container style={{ marginTop: "1rem" }}>
                      <Grid item md={12} xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ marginTop: ".5rem" }}
                          disabled={state.isSavingQuestionnaireAdvice}
                          onClick={() => {
                            saveQuestionnaireAdvice(
                              token,
                              recommendedCategories,
                              recommenderName,
                              recommendationDescription
                            );
                          }}
                        >
                          Opslaan
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    state: state.recommendations,
    questionnaire: state.questionnaire.questionnaire
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startRecommendationEngine: questionnaire => {
      dispatch(startRecommendationEngine(questionnaire));
    },
    startInternalRecommendationEngine: questionnaire => {
      dispatch(startInternalRecommendationEngine(questionnaire));
    },
    checkAiFlow: (flowId, executionId) => {
      dispatch(checkAiFlow(flowId, executionId));
    },
    getRecommendations: flowId => {
      dispatch(queryRecommendations(flowId));
    },
    loadAvailableCategories: () => {
      dispatch(loadAvailableCategories());
    },
    loadQuestionnaireAdvices: token => {
      dispatch(loadQuestionnaireAdvices(token));
    },
    saveQuestionnaireAdvice: (token, categoryIds, name, message) => {
      dispatch(saveQuestionnaireAdvice(token, categoryIds, name, message));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryRecommendations);
